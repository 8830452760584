import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type { ProblemDomain } from '@mathflat/domain/@entities'
import { observer } from 'mobx-react'
import { nanoid } from 'nanoid'
import type { CSSProperties } from 'react'

import { RequestScoring } from '~/@common/api'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import type { DefaultProps } from '~/@common/types'
import {
  SwiperControllerConsumerName,
  useSwiperControllerContext,
} from '~/@common/ui/SwiperController/SwiperController.context'

import type { ProblemScoring } from '../(ProblemScoring)/@trait/ProblemScoring.trait'

/**
 * 문제풀이 현황판 공통 컴포넌트
 * @param problems result만 담고 있으면 어떤 배열이든 상관없습니다.
 * @param swiper는 현재 active된 swiper 객체를 받아옵니다.
 */
interface Props {
  problemScorings: ProblemScoring<'WORKSHEET'>[]
  onClickItem?: () => void
}

export const ProblemSolvingStatus = observer(({ problemScorings, onClickItem }: Props) => {
  const swiperController = useSwiperControllerContext(SwiperControllerConsumerName)

  const { isMobile } = useStudentAppMediaQuery()

  const onCircleItemClick = (swiperIdx: number) => {
    swiperController?.control?.slideTo(swiperIdx)
    onClickItem?.()
  }

  return (
    <S.Container>
      {isMobile ? (
        <div className="container__top">
          <div className="container__top-guide">
            <div className="top__guid-round">
              <span>안 푼 문제</span>
              <Round />
            </div>
            <div className="top__guid-round">
              <span>푼 문제</span>
              <Round backgroundColor={colors.gray.$200} />
            </div>
            <div className="top__guid-round">
              <span>모르는 문제</span>
              <Round backgroundColor="#FFF6EC" borderColor={colors.yellow} />
              <span>로 표시해주세요.</span>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="container__top"
          style={{
            justifyContent: 'space-between',
          }}
        >
          <h2 className="container__top-title">문제풀이 현황</h2>
          <div className="container__top-guide">
            <div className="top__guid-round">
              <span>안 푼 문제</span>
              <Round />
            </div>
            <div className="top__guid-round">
              <span>푼 문제</span>
              <Round backgroundColor={colors.gray.$200} />
            </div>
            <div className="top__guid-round">
              <span>모르는 문제</span>
              <Round backgroundColor="#FFF6EC" borderColor={colors.yellow} />
            </div>
          </div>
        </div>
      )}
      <div className="container__bottom">
        <ul className="container__bottom-list">
          {problemScorings.map((problemScoring, index) => (
            <li key={nanoid()}>
              <CircleItem
                index={index}
                problemScoring={problemScoring}
                onCircleItemClick={() => onCircleItemClick(index)}
              />
            </li>
          ))}
        </ul>
      </div>
    </S.Container>
  )
})

type ProblemScoringResultType = ProblemDomain.ScoringResult | 'selected'

type ItemProps = {
  index: number
  problemScoring: ProblemScoring<'WORKSHEET'>
  onCircleItemClick: () => void
}

const CircleItem = observer(({ index, problemScoring, onCircleItemClick }: ItemProps) => {
  const type: ProblemScoringResultType = (() => {
    const currentUserAnswer = problemScoring.localUserInput

    if (currentUserAnswer instanceof RequestScoring.자동채점) {
      if (currentUserAnswer.localUnknown) return 'UNKNOWN'
      else if (currentUserAnswer.localUserAnswer) return 'selected'
    }

    return problemScoring.채점결과
  })()

  return (
    <Circle size={50} type={type} onClick={onCircleItemClick}>
      {index + 1}
    </Circle>
  )
})

interface RoundProps {
  size?: number
  borderColor?: CSSProperties['borderColor']
  backgroundColor?: CSSProperties['backgroundColor']
}

const Round = ({
  size = 12,
  backgroundColor = colors.white,
  borderColor = colors.gray.$400,
}: RoundProps) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: 'var(--Radius-Circle)',
        border: `1px solid ${borderColor}`,
        backgroundColor,
      }}
    />
  )
}

type CircleProps = {
  type: ProblemScoringResultType
  size?: number
  onClick?: React.MouseEventHandler<HTMLButtonElement>
} & DefaultProps

export const Circle = ({ children, className, type, size = 40, onClick }: CircleProps) => {
  return (
    <S.Circle className={className} css={[S.type(type), S.size(size)]} onClick={onClick}>
      {children}
    </S.Circle>
  )
}

const S = {
  Container: styled.section`
    display: flex;
    flex-direction: column;
    height: 100%;

    &.minWidth {
      min-width: 384px;
    }

    &.fullWidth {
      width: 100%;
    }

    .container__top {
      display: flex;
      align-items: center;
      flex: 0 0 70px;
      padding: 20px;
      padding-right: 32px;
      gap: 10px;
      border-top-right-radius: var(--Radius-300);
      border-top-left-radius: var(--Radius-300);
      min-width: 384px;
      background-color: ${colors.white};
      border-bottom: 1px solid ${colors.gray.$300};
      ${mediaQuery.underTablet} {
        padding: 0 20px;
        flex: 0 0 38px;
      }
    }

    .container__top-title {
      font-weight: 700;
      color: ${colors.gray.$900};
      ${typo.body01}
    }
    .container__top-guide {
      display: flex;
      gap: 10px;
      color: ${colors.gray.$800};
      ${typo.caption01}
    }
    .top__guid-round {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .top__subjectSummary {
      color: ${colors.gray.$700};
      ${typo.body02}
    }
    .top__problemIndex {
      min-width: max-content;
      color: ${colors.gray.$900};
      ${typo.body01}
    }
    .top__answerAmount {
      padding: 4px 12px;
      border-radius: var(--Radius-200);
      background-color: ${colors.gray.$200};
      color: ${colors.gray.$800};
      min-width: max-content;
      ${typo.body02}
    }
    .top__toolbarContainer {
      width: 340px;
      position: relative;
      flex-shrink: 0;
      margin-left: auto;
    }

    .container__bottom {
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-bottom-right-radius: var(--Radius-300);
      border-bottom-left-radius: var(--Radius-300);
      padding: 20px;
      background-color: ${colors.white};
      flex: 1 1 100%;
      overflow: hidden;
    }

    .container__bottom-list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      row-gap: 25px;
      column-gap: 20px;
      overflow: scroll;
    }

    .container__bottom--scoring-form-wrapper {
      display: flex;
    }
  `,
  Circle: styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--Radius-Circle);
    ${typo.body01}
    :active {
      filter: contrast(0.95);
    }
  `,
  type: (type: ProblemScoringResultType) => {
    switch (type) {
      case 'UNKNOWN':
        return css`
          border: 1px solid ${colors.yellow};
          background-color: #fff6ec;
          color: ${colors.yellow};
        `
      case 'NONE':
        return css`
          border: 1px solid ${colors.gray.$400};
          background-color: white;
        `
      case 'CORRECT':
      case 'WRONG':
      case 'selected':
        return css`
          border: 1px solid ${colors.gray.$400};
          background-color: ${colors.gray.$200};
          color: ${colors.gray.$800};
        `
    }
  },
  size: (size: number) => css`
    width: ${size}px;
    height: ${size}px;
  `,
}
