import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'

import { RequestScoring } from '~/@common/api/request/RequestScoring'
import { routeName } from '~/@common/constants'
import { useLearningTime } from '~/@common/hooks/useLearningTime'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useRepository } from '~/@common/hooks/useRepository'
import { learningContentLastScreenService } from '~/@common/services/learningContentLastScreen/LearningContentLastScreen.service'
import modalService from '~/@common/services/modal.service'
import { commonRepo } from '~/@common/services/repo.service'
import WorksheetScoringByOne from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/WorksheetScoringByOne'
import WorksheetScoringByOneMobile from '~/@pages/@widgets/(Worksheet)/WorksheetScoring/WorksheetScoringByOne.mobile'
import { openUnavailableAlertMessageModal } from '~/@pages/@widgets/(Worksheet)/접근권한모달'

import { ScoringSubmitConfirmModal } from '../../@widgets/StudentWorksheetScoring/ScoringSubmitConfirmModal'
import { StudentWorksheetDetailPageService } from './@service/StudentWorksheetDetailPage.service'
import { StudentWorksheetHeader } from './@widgets/StudentWorksheetHeader'
import { alertWorksheetExceptionErrorModal } from './scoring/alertWorksheetExceptionErrorModal'

const StudentWorksheetDetailPage = observer(() => {
  useLearningTime()
  const { studentWorksheetId } = useParams<{ studentWorksheetId: string }>()
  const navigate = useNavigate()

  // TODO: /student-worksheet 나갈 때 dynamicRepo.del 시킬 수 있도록, student-worksheet을 감싸는 페이지 컴포넌트 신설 필요
  const service = useRepository([StudentWorksheetDetailPageService, studentWorksheetId!])

  const { isMobile } = useStudentAppMediaQuery()

  useEffect(() => {
    if (studentWorksheetId && commonRepo.studentAppSetting) {
      service.loadAndSetStudentWorksheetScorings(+studentWorksheetId)
    }
  }, [studentWorksheetId, JSON.stringify(commonRepo.studentAppSetting)])

  // 최초 진입(reload)시 비공개 학습지일 경우 이전 페이지로 돌아감.
  useEffect(() => {
    if (service.worksheet?.accessModifierToStudent === 'PRIVATE') {
      navigate(-1)
    }
  }, [service.worksheet?.accessModifierToStudent])

  useEffect(() => {
    if (!studentWorksheetId) {
      navigate(routeName.student.studentWorksheet)
    }
  }, [studentWorksheetId])

  const onSubmitProblems = () => {
    if (service.problemScoringColl?.toScoredArr.length) {
      modalService.openModal(
        {
          content: (
            <ScoringSubmitConfirmModal
              onSubmit={async () => {
                if (!studentWorksheetId) {
                  return
                }
                try {
                  modalService.showLoader()
                  modalService.closeModal()
                  await service.onSubmitWorksheetProblems(studentWorksheetId)
                } catch (error) {
                  if (error instanceof Error) {
                    // CASE: 학습지가 삭제되거나 수정된 경우
                    if (
                      error.message === 'WORKSHEET_ALREADY_DELETED' ||
                      error.message === 'STUDENT_WORKSHEET_NOT_FOUND'
                    ) {
                      alertWorksheetExceptionErrorModal()
                    } else if (error.message === 'WORKSHEET_PERMISSION_DENIED') {
                      openUnavailableAlertMessageModal()
                    } else {
                      // CASE: 학습지에 대한 접근 권한이 없을 때
                      openUnavailableAlertMessageModal()
                    }
                  }
                } finally {
                  modalService.hideLoader()
                }
              }}
            />
          ),
        },
        {
          modalName: 'confirmToSubmitAnswers',
        },
      )
    }
  }

  const onVirtualScoringSubmit = async (
    localUserInput: RequestScoring.자동채점<'WORKSHEET'> | RequestScoring.일반채점<'WORKSHEET'>,
  ) => {
    if (!service.worksheet?.autoScorable || !(localUserInput instanceof RequestScoring.자동채점))
      return

    await service.onVirtualScoringSubmitById(Number(studentWorksheetId), localUserInput)
  }

  if (!studentWorksheetId) return null

  const lastScreenProblemIndex = learningContentLastScreenService.getLastScreenProblemIndex([
    'STUDENT_WORKSHEET',
    +studentWorksheetId,
  ])

  const updateLastScreenProblemIndex = (currentProblemIndex: number) => {
    if (studentWorksheetId) {
      learningContentLastScreenService.setLastScreenProblemIndex(
        ['STUDENT_WORKSHEET', +studentWorksheetId],
        currentProblemIndex,
      )
    }
  }

  return (
    <>
      <StudentWorksheetHeader title={service.worksheet?.title} />
      {isMobile ? (
        <WorksheetScoringByOneMobile
          problemScoringColl={service.problemScoringColl}
          viewOption={service.problemScoringViewOption}
          onSubmit={onSubmitProblems}
          studentWorksheet={service.studentWorksheet}
          type={service.worksheet?.type}
          problemIndex={lastScreenProblemIndex}
          onProblemIndexChange={updateLastScreenProblemIndex}
          onLocalUserInputChange={onVirtualScoringSubmit}
        />
      ) : (
        <WorksheetScoringByOne
          problemScoringColl={service.problemScoringColl}
          viewOption={service.problemScoringViewOption}
          onSubmit={onSubmitProblems}
          studentWorksheet={service.studentWorksheet}
          worksheet={service.worksheet}
          problemIndex={lastScreenProblemIndex}
          onProblemIndexChange={updateLastScreenProblemIndex}
          onLocalUserInputChange={onVirtualScoringSubmit}
        />
      )}
    </>
  )
})

export default StudentWorksheetDetailPage
