import { observer } from 'mobx-react'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import { PIXEL_RATIO } from '../constants'
import type { PanEvent } from '../hooks'
import { POINTER_TYPE, useGestureEvent } from '../hooks'
import { errorHandlerService } from '../services/errorHandler.service'
import type { Size } from '../types'
import type {
  HandwrittenNoteReadFunc,
  HandwrittenNoteWriteFunc,
  NoteData,
} from './handwrittenNote.types'
import HandwrittenNoteEraserIndicator from './HandwrittenNoteEraserIndicator'
import HandwrittenNotePath from './HandwrittenNotePath'
import HandwrittenNoteService from './service/HandwrittenNote.service'
import type HandwrittenNoteControllerService from './service/HandwrittenNoteController.service'
import HandwrittenNotePathItem from './service/HandwrittenNotePathItem'

interface Props {
  readFunc?: HandwrittenNoteReadFunc
  writeFunc?: HandwrittenNoteWriteFunc
  viewBoxSize: Size
  controllerService?: HandwrittenNoteControllerService
  saveDelay?: number
  noteData?: NoteData
  readonlyNoteData?: NoteData
  renderPriority?: 'readonlyNoteData' | 'noteData'
}

const HandwrittenNote: FC<Props> = ({
  readFunc,
  writeFunc,
  viewBoxSize,
  controllerService,
  saveDelay,
  noteData,
  readonlyNoteData,
  renderPriority = 'noteData',
}) => {
  const serviceRef = useRef(new HandwrittenNoteService(controllerService))
  const service = serviceRef.current
  const { isWritingMode, needExtraFinger } = service.controllerService

  useEffect(() => {
    service.controllerService.setNoteService(service)
  }, [service])

  useEffect(() => {
    service.reset()

    if (readFunc) {
      readFunc()
        .then((noteData) => {
          if (noteData) {
            service.noteData.fromJSON(noteData)
          }
        })
        .catch(errorHandlerService.handle)
    }
  }, [readFunc, service])

  useEffect(() => {
    service.reset()

    if (noteData && !readFunc) {
      service.noteData.fromJSON(noteData)
    }
  }, [noteData, service])

  useEffect(() => {
    service.setViewBoxSize(viewBoxSize)
  }, [service, viewBoxSize])

  useEffect(() => {
    if (writeFunc) {
      service.setWriterFunc(writeFunc)
    }
  }, [service, writeFunc])

  const handlePanStart = useCallback(
    (e: PanEvent) => {
      if (!isWritingMode) {
        return
      }
      if (!needExtraFinger && e.pointerType === POINTER_TYPE.touch) {
        return
      }
      service.handlePanStart(e)
    },
    [needExtraFinger, isWritingMode, service],
  )

  const handlePan = useCallback(
    (e: PanEvent) => {
      if (!isWritingMode) {
        return
      }
      if (!needExtraFinger && e.pointerType === POINTER_TYPE.touch) {
        return
      }
      service.handlePan(e)
    },
    [needExtraFinger, isWritingMode, service],
  )

  const handlePanEnd = useCallback(
    (e: PanEvent) => {
      if (!isWritingMode) {
        return
      }
      if (!needExtraFinger && e.pointerType === POINTER_TYPE.touch) {
        return
      }
      service.handlePanEnd()
    },
    [needExtraFinger, isWritingMode, service],
  )

  const gestureEventOptions = useMemo(() => {
    return {
      usePointerCapture: true,
      minMoveDistance: 0,
      moveDelay: 0,
      preventDefaultTouchMove: true,
      onPanStart: handlePanStart,
      onPan: handlePan,
      onPanEnd: handlePanEnd,
    }
  }, [handlePan, handlePanEnd, handlePanStart])

  const { gestureRef } = useGestureEvent<SVGSVGElement>(gestureEventOptions)
  const viewBoxSizeString = `0 0 ${viewBoxSize.width * PIXEL_RATIO} ${viewBoxSize.height * PIXEL_RATIO}`

  const handleAutoToolbarHide = useCallback(() => {
    service.controllerService.setToolbarMode('simple')
  }, [service.controllerService])

  useEffect(() => {
    if (service.isModified) {
      service.requestSave(saveDelay).catch(errorHandlerService.handle)
    }
  }, [saveDelay, service, service.isModified])

  const readonlyPathItems = useMemo(() => {
    if (!readonlyNoteData) {
      return []
    }
    return readonlyNoteData.paths.map(HandwrittenNotePathItem.fromData)
  }, [readonlyNoteData])

  return (
    <>
      {readonlyNoteData && (
        <svg
          style={{
            position: 'absolute',
            inset: 0,
            pointerEvents: 'none',
            display: service.controllerService.isHiddenNote ? 'none' : 'unset',
            zIndex: renderPriority === 'readonlyNoteData' ? 1 : 0,
          }}
          viewBox={`0 0 ${readonlyNoteData.viewBox[0] * PIXEL_RATIO} ${readonlyNoteData.viewBox[1] * PIXEL_RATIO}`}
        >
          {readonlyPathItems.map((item) => (
            <HandwrittenNotePath key={item.id} item={item} service={service} />
          ))}
        </svg>
      )}
      <svg
        ref={gestureRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          touchAction: 'none',
          display: service.controllerService.isHiddenNote ? 'none' : 'unset',
          width: `${PIXEL_RATIO * 100}%`,
          height: `${PIXEL_RATIO * 100}%`,
          transform: `scale(${1 / PIXEL_RATIO})`,
          transformOrigin: '0 0',
        }}
        viewBox={viewBoxSizeString}
        onPointerDown={handleAutoToolbarHide}
      >
        {service.noteData.visiblePathItems.map((item) => (
          <HandwrittenNotePath key={item.id} item={item} service={service} />
        ))}
      </svg>
      <HandwrittenNoteEraserIndicator service={service} />
    </>
  )
}

export default observer(HandwrittenNote)
