import { ANSWER_TYPE } from '@mathflat/domain/@entities/Problem/constants'
import type { Entity as ScoringEntity } from '@mathflat/domain/@entities/Scoring/dto'
import type { Entity as StudentWorkbookScoringEntity } from '@mathflat/domain/@entities/StudentWorkbook/StudentWorkbookScoring/dto'
import type { MathflatApi } from '@mathflat/domain/@entities/StudentWorksheet/api'
import type { Entity as StudentWorksheetScoringEntity } from '@mathflat/domain/@entities/StudentWorksheet/StudentWorksheetScoring/dto'
import { difference } from 'lodash'
import { action, computed, makeAutoObservable, makeObservable, observable } from 'mobx'

import { RequestScoring } from '~/@common/api'
import { localStorageService } from '~/@common/services/storage.service'
import type { Problem } from '~/@common/types'

export class ProblemScoring<T extends 'WORKSHEET' | 'WORKBOOK'> {
  id: ScoringEntity.Scoring<T>['id']
  contentProblemId: Problem['id']

  private _localAutoScoredAnswer: string | null = null
  private _localScoring: ScoringEntity.Scoring['result'] = ANSWER_TYPE.NONE
  private _scoring: T extends 'WORKSHEET'
    ? StudentWorksheetScoringEntity.StudentWorksheetScoring
    : StudentWorkbookScoringEntity.StudentWorkbookScoring
  private _scoringSaveStorage: 'LOCAL_STORAGE' | 'SERVER' = 'LOCAL_STORAGE'
  private _handwrittenNoteSaveStorage: 'INDEXED_DB' | 'SERVER' = 'INDEXED_DB'
  handwrittenNoteUrl: string | null = null

  constructor(
    params: {
      handwrittenNoteSaveStorage?: 'INDEXED_DB' | 'SERVER'
      scoringSaveStorage?: 'LOCAL_STORAGE' | 'SERVER'
      scoring: T extends 'WORKSHEET'
        ? StudentWorksheetScoringEntity.StudentWorksheetScoring
        : StudentWorkbookScoringEntity.StudentWorkbookScoring
    },
    contentProblemId: Problem['id'],
    handwrittenNoteUrl: string | null = null,
    virtualScores?: MathflatApi.Response.StudentWorksheetVirtualScoring['virtualScores'],
  ) {
    this.id = params.scoring.id
    this._scoring = params.scoring
    this._scoringSaveStorage = params.scoringSaveStorage || 'LOCAL_STORAGE'
    this._handwrittenNoteSaveStorage = params.handwrittenNoteSaveStorage || 'INDEXED_DB'
    this.handwrittenNoteUrl = handwrittenNoteUrl

    this._setLocalUserAnswerUnformatted(virtualScores)

    makeObservable<
      typeof this,
      '_localAutoScoredAnswer' | '_localScoring' | '_setLocalUserAnswerUnformatted'
    >(this, {
      _localAutoScoredAnswer: observable,
      _localScoring: observable,
      handwrittenNoteUrl: observable,
      localUserInput: computed,
      setLocalUserInput: action,
      isAutoScoring: computed,
      scoringType: computed,
      _setLocalUserAnswerUnformatted: action,
      handwrittenNoteSaveStorage: computed,
    })

    this.contentProblemId = contentProblemId
  }
  get handwrittenNoteSaveStorage() {
    return this._handwrittenNoteSaveStorage
  }
  get problem() {
    return this._scoring.problem as T extends 'WORKSHEET'
      ? StudentWorksheetScoringEntity.StudentWorksheetScoring['problem']
      : StudentWorkbookScoringEntity.StudentWorkbookScoring['problem']
  }
  get problemId() {
    if ('problemId' in this._scoring.problem) {
      return this._scoring.problem.problemId
    } else {
      return this._scoring.problem.id
    }
  }
  // 자동채점여부
  get isAutoScoring() {
    return this.scoringType === '자동채점'
  }

  get scoringType() {
    return this._scoring.isAutoScorable ? '자동채점' : '일반채점'
  }
  get isSubmitted() {
    return this._scoring.isSubmitted
  }

  get 채점결과() {
    return this._scoring.채점결과
  }

  get 오답_모르는문제여부() {
    return this._scoring.오답_모르는문제여부
  }

  get 난이도() {
    return this._scoring.난이도
  }

  get keypadTypes() {
    return this._scoring.keypadTypes
  }

  get answerUnits() {
    return this._scoring.answerUnits
  }

  get 문제n지선다() {
    return this._scoring.문제n지선다
  }

  get 문제정답길이() {
    return this._scoring.문제정답길이
  }

  get 제출한답() {
    return this._scoring.제출한답
  }

  get 문제이미지() {
    return this._scoring.문제이미지
  }

  get 문제정답타입() {
    return this._scoring.문제정답타입
  }

  get 문제정답이미지() {
    return this._scoring.문제정답이미지 as T extends 'WORKSHEET'
      ? StudentWorksheetScoringEntity.StudentWorksheetScoring['문제정답이미지']
      : StudentWorkbookScoringEntity.StudentWorkbookScoring['문제정답이미지']
  }

  get 문제해설이미지() {
    return this._scoring.문제해설이미지
  }

  get 문제번호() {
    return this._scoring.문제번호
  }

  get 문제정답() {
    return this._scoring.문제정답
  }
  get 학제학년학기() {
    return this._scoring.학제학년학기
  }
  // 외부에서 localUserInput 접근할 때
  get localUserInput() {
    // 자동채점이고, 답이 입력 되었을 때
    if (this.isAutoScoring) {
      return new RequestScoring.자동채점<T>({
        scoring: this._scoring,
        localUserAnswer: this._localAutoScoredAnswer,
        localUnknown: this._localAutoScoredAnswer === ANSWER_TYPE.UNKNOWN,
      })
    }

    return new RequestScoring.일반채점({
      scoring: this._scoring,
      localResult: this._localScoring,
    })
  }

  // 서버에서 받아온 가채점 데이터를 정답 인풋에 맞게 변환
  private _setLocalUserAnswerUnformatted(
    virtualScores: MathflatApi.Response.StudentWorksheetVirtualScoring['virtualScores'] | undefined,
  ) {
    let localAnswer =
      this._scoringSaveStorage === 'LOCAL_STORAGE'
        ? localStorageService.scoring.get(this.id)
        : virtualScores?.find((item) => item.worksheetProblemId === this.id)?.userAnswer

    if (!localAnswer) return
    if (this._scoring.isAutoScorable) {
      if (this._scoring.isSubmitted) return
      if (this._scoring.문제정답타입 !== 'SHORT_ANSWER') {
        this._localAutoScoredAnswer = localAnswer
        return
      }

      const unit = this._scoring.answerUnits
      const prefixes = unit && unit.filter((item) => item.index === 0)
      const postfixes = unit && unit.filter((item) => item.index !== 0)
      if (prefixes?.length || postfixes?.length) {
        // 모든 prefix 제거
        prefixes?.forEach((prefix) => {
          const unitText = prefix.unit.match(/\[(.*?)\]/)?.[1] || ''
          localAnswer = localAnswer?.replace(unitText, '')
        })
        // 모든 postfix 제거
        postfixes?.forEach((postfix) => {
          const unitText = postfix.unit.match(/\[(.*?)\]/)?.[1] || ''
          localAnswer = localAnswer?.replace(unitText, '')
        })
      }
      this._localAutoScoredAnswer = localAnswer
    } else {
      this._localScoring = localAnswer as ScoringEntity.Scoring['result']
    }
  }

  setLocalUserInput = <T extends 'WORKSHEET' | 'WORKBOOK'>(
    localAnswer: ProblemScoring<T>['_localAutoScoredAnswer'] | ProblemScoring<T>['_localScoring'],
  ) => {
    if (!this.isSubmitted) {
      if (this.isAutoScoring) {
        this._localAutoScoredAnswer = localAnswer
      } else {
        this._localScoring = (
          localAnswer === null ? 'NONE' : localAnswer
        ) as ProblemScoring<T>['_localScoring']
      }

      if (localAnswer === '' || localAnswer === null) {
        localStorageService.removeScoringData({ scoringId: this.id })
      } else {
        if (this._scoringSaveStorage === 'LOCAL_STORAGE') {
          localStorageService.setScoringData({ scoringId: this.id, localAnswer })
        }
      }
      return
    }
    localStorageService.removeScoringData({ scoringId: this.id })
  }
}

export class ProblemScoringColl<T extends 'WORKSHEET' | 'WORKBOOK'> {
  private _problemScorings: ProblemScoring<T>[]

  constructor(problemScorings: ProblemScoring<T>[]) {
    makeAutoObservable(this)
    this._problemScorings = problemScorings
  }

  get toArr() {
    return this._problemScorings
  }

  get toCorrectArr() {
    return this._problemScorings.filter((problemScoring) => {
      return problemScoring.채점결과 === ANSWER_TYPE.CORRECT
    })
  }

  get toWrongArr() {
    return this._problemScorings.filter((problemScoring) => {
      return problemScoring.채점결과 === ANSWER_TYPE.WRONG
    })
  }

  get toNoneArr() {
    return this._problemScorings.filter((problemScoring) => {
      return problemScoring.채점결과 === ANSWER_TYPE.NONE
    })
  }

  get toUnknowonArr() {
    return this._problemScorings.filter((problemScoring) => {
      return problemScoring.채점결과 === ANSWER_TYPE.UNKNOWN
    })
  }

  // 마킹안한 첫번째 문제 인덱스
  get notMarkingProblemFirstIndex() {
    const probemScoringIds = this._problemScorings.map((problemScoring) => problemScoring.id)
    const localScoringIds = this.toScoredArr.map((scoring) => scoring.id)
    const notMarkingArr = difference(probemScoringIds, localScoringIds)
    return probemScoringIds.indexOf(notMarkingArr[0])
  }

  // 채점 전 모든 문제의 답안이 모두 입력되었는지에 대한 여부
  get isAllLocallyScored() {
    return this.toScoredArr.length === this.toNoneArr.length
  }

  get toWrongOrUnknwonArr() {
    return this._problemScorings.filter((problemScoring) => problemScoring.오답_모르는문제여부)
  }

  // 로컬 채점된 배열
  get toScoredArr() {
    return this._problemScorings.filter(
      (problemScoring) => !problemScoring.localUserInput.isNotLocallyScored,
    )
  }

  // 하나도 채점되지 않았을 경우
  get isNothingSubmitted() {
    return this.toNoneArr.length === this.toArr.length
  }

  get gradingCount() {
    const { correct, incorrect, unknown, none } = this.toArr.reduce(
      (accumulator, { 채점결과 }) => {
        switch (채점결과) {
          case ANSWER_TYPE.CORRECT:
            return { ...accumulator, correct: accumulator.correct + 1 }
          case ANSWER_TYPE.WRONG:
            return { ...accumulator, incorrect: accumulator.incorrect + 1 }
          case ANSWER_TYPE.UNKNOWN:
            return { ...accumulator, unknown: accumulator.unknown + 1 }
          case ANSWER_TYPE.NONE:
            return { ...accumulator, none: accumulator.none + 1 }
          default:
            return accumulator
        }
      },
      { correct: 0, incorrect: 0, unknown: 0, none: 0 },
    )
    return { correct, incorrect, unknown, none }
  }

  onAllCheckCorrect() {
    this._problemScorings.forEach((problemScoring) => {
      problemScoring.setLocalUserInput(ANSWER_TYPE.CORRECT)
    })
  }

  onAllClear = () => {
    this._problemScorings.forEach((problemScoring) => {
      problemScoring.setLocalUserInput(null)
    })
  }
}
