import type { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import type { MathflatApi } from '@mathflat/domain/@entities/StudentWorksheet/api'
import { Entity as StudentWorksheetEntity } from '@mathflat/domain/@entities/StudentWorksheet/dto'
import type { DefaultResponse } from '@mathflat/shared/@types/mathflatApi'
import type { Pagination } from '@mathflat/shared/@types/mathflatApi'

import maxios from '../utils/maxios'

export const studentWorksheetApi = {
  // 학생-학습지와 문제 조회하기
  getWorksheetWithProblems: (worksheetId: string) => {
    return maxios.get<MathflatApi.Response.WorksheetWithProblems>(
      `/student-worksheet/worksheet/${worksheetId}`,
    )
  },

  getStudentWorksheetWithHomeworks: async (
    studentId: string,
    params: {
      startDate: string
      endDate: string
    },
  ) => {
    const { data } = await maxios.get<
      MathflatApi.Response.WorksheetAndStudentWorksheetWithHomeworks[]
    >(`/student-worksheet/${studentId}/homeworks`, {
      params,
    })

    return data.map(
      ({ homeworks, worksheet, selfLearningStudentWorksheets, ...studentWorksheet }) => ({
        worksheet,
        homeworks,
        selfLearningStudentWorksheets: selfLearningStudentWorksheets
          .map(({ worksheet, ...studentWorksheet }) => ({
            studentWorksheet: new StudentWorksheetEntity.StudentWorksheet(
              studentWorksheet as ConstructorParameters<
                typeof StudentWorksheetEntity.StudentWorksheet
              >[0],
            ),
            worksheet: worksheet as
              | WorksheetEntity.LevelUpWorksheet
              | WorksheetEntity.WrongConceptWorksheet,
            group: selfLearningStudentWorksheets
              .filter(
                (item) =>
                  item.worksheet.titleTag === worksheet.titleTag &&
                  item.worksheet.selfLearningType === worksheet.selfLearningType,
              )
              .map(({ worksheet, ...studentWorksheet }) => ({
                studentWorksheet: new StudentWorksheetEntity.StudentWorksheet(
                  studentWorksheet as ConstructorParameters<
                    typeof StudentWorksheetEntity.StudentWorksheet
                  >[0],
                ),
                worksheet: worksheet as
                  | WorksheetEntity.LevelUpWorksheet
                  | WorksheetEntity.WrongConceptWorksheet,
              })),
          }))
          .filter((item) => item.worksheet.id === item.group[0].worksheet.id),
        studentWorksheet,
      }),
    )
  },

  // 자기주도학습 학습지가 부모, 형제 학습지를 조회할 수 있다.
  getSelfLearningStudentWorksheets: (params: { studentWorksheetId: string }) => {
    return maxios.get<MathflatApi.Response.SelfLearningStudentWorksheets>(
      `/student-learning/self-learning`,
      { params },
    )
  },

  postWorksheetValidation: (
    worksheetValidation: MathflatApi.Request.StudentWorksheetValidation,
  ) => {
    return maxios.post<
      MathflatApi.Response.StudentWorksheetValidation,
      DefaultResponse<MathflatApi.Response.StudentWorksheetValidation>,
      MathflatApi.Request.StudentWorksheetValidation
    >('/student-worksheet/validation', worksheetValidation)
  },

  getAssignedStudentWorksheetById: async (
    studentWorksheetId: StudentWorksheetEntity.StudentWorksheet['id'],
    params?,
  ) => {
    const {
      data: { worksheet, ...studentWorksheet },
    } = await maxios.get<MathflatApi.Response.WorksheetAndStudentWorksheet>(
      `/student-worksheet/assign/${studentWorksheetId}`,
      { params },
    )
    return {
      studentWorksheet: new StudentWorksheetEntity.StudentWorksheet(studentWorksheet),
      worksheet,
    }
  },

  getAssignedStudentWorksheetWithProblems: async (
    studentWorksheetId: StudentWorksheetEntity.StudentWorksheet['id'],
    params?: MathflatApi.Request.StudentWorksheetWithProblemsParams,
  ) => {
    const {
      data: { content },
    } = await maxios.get<Pagination<MathflatApi.Response.StudentWorksheetScoring[]>>(
      `/student-worksheet/assign/${studentWorksheetId}/problem`,
      { params },
    )
    return content.map(({ problem, worksheetProblemId, handwrittenNoteUrl, ...scoring }) => ({
      problem,
      worksheetProblemId,
      scoring,
      handwrittenNoteUrl,
    }))
  },
  patchScoringAssignedStudentWorksheet: (
    studentWorksheetId: string,
    scoringList: MathflatApi.Request.StudentWorksheetScoring[],
  ) => {
    return maxios.patch(`/student-worksheet/assign/${studentWorksheetId}/scoring`, scoringList)
  },

  patchAutoScoringAssignedStudentWorksheet: (
    studentWorksheetId: string,
    scoringList: MathflatApi.Request.StudentWorksheetAutoScoring[],
  ) => maxios.patch(`/student-worksheet/assign/${studentWorksheetId}/auto-scoring`, scoringList),

  postStudentCheat: (studentId: string, studentWorksheetId: string) =>
    maxios.post(`/student-worksheet/student/${studentId}/${studentWorksheetId}/cheat`),

  // 학생이 자신에게 학습지를 출제하도록 요청한다.
  postToAssignWorksheetToStudent: (worksheetId: string) => {
    return maxios.post(`/student-worksheet/${worksheetId}`)
  },

  // 가채점을 위해 N문제씩 임시 제출(로컬스토리지 저장을 대체 함)
  postVirtualAutoScoringProblem: async (
    studentWorksheetId: StudentWorksheetEntity.StudentWorksheet['id'],
    scoringList: MathflatApi.Request.StudentWorksheetVirtualAutoScoring[],
  ) => {
    return await maxios.post(
      `/student-worksheet/${studentWorksheetId}/virtual-scoring`,
      scoringList,
    )
  },
  // 가채점 문제 초기화
  deleteVirtualAutoScoringProblem: async (
    studentWorksheetId: StudentWorksheetEntity.StudentWorksheet['id'],
    worksheetProblemIds?: MathflatApi.Request.StudentWorksheetVirtualAutoScoring['worksheetProblemId'][],
  ) => {
    return await maxios.delete(`/student-worksheet/${studentWorksheetId}/virtual-scoring`, {
      params: {
        worksheetProblemIds,
      },
    })
  },
  // 가채점 조회
  getVirtualAutoScoringAssignedStudentWorksheet: async (
    studentWorksheetIds: StudentWorksheetEntity.StudentWorksheet['id'][],
  ) => {
    const { data } = await maxios.get<MathflatApi.Response.StudentWorksheetVirtualScoring[]>(
      `/student-worksheet/virtual-scoring`,
      {
        params: {
          studentWorksheetIds,
        },
      },
    )
    return data
  },
}
