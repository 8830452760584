import 'simplebar-react/dist/simplebar.min.css'

import styled from '@emotion/styled'
import { ANSWER_TYPE } from '@mathflat/domain/@entities/Problem/constants'
import { s3URL } from '@mathflat/shared/@common/utils/s3'
import { debounce } from 'lodash'
import { observer } from 'mobx-react'
import { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router'

import { RequestScoring } from '~/@common/api'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { CustomEventService } from '~/@common/services/event.service'
import modalService from '~/@common/services/modal.service'
import { colors, typo } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'
import type { StudentWorkbookDetailService } from '~/@pages/student/student-workbook/detail/@service/StudentWorkbookDetail.service'

import { 일반채점입력 } from '../../(Scoring)/(일반채점)/일반채점입력'
import 자동채점입력 from '../../(Scoring)/(자동채점)/자동채점입력'
import type { 자동채점입력_주관식Props } from '../../(Scoring)/(자동채점)/자동채점입력/자동채점입력_주관식'
import { SubmittedAnswer, 채점결과_말풍선 } from '../../(Scoring)/(채점결과)/채점결과'
import type { ProblemScoring } from '../@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '../@trait/ProblemScoringViewOption.trait'
import ProblemScoringStyle from '../ProblemScoringCard/ProblemScoringCard.style'
import { AnswerVideoDrawer } from './_component/AnswerVideoDrawer/AnswerVideoDrawer'

export type ProblemScoringFooterProps = {
  contentId?: string
  problemScoring: ProblemScoring<'WORKSHEET' | 'WORKBOOK'>
  viewOption?: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'>
  isSubmittedAnswerAvailable?: boolean
  onSubmitVideoAssist?: StudentWorkbookDetailService['onSubmitVideoAssist']
  onLocalUserInputChange?: (
    localUserInput: RequestScoring.자동채점 | RequestScoring.일반채점,
  ) => void
} & Pick<자동채점입력_주관식Props, 'virtualKeybaordShowType'>

const ProblemScoringFooter = observer(
  ({
    contentId,
    problemScoring,
    viewOption,
    virtualKeybaordShowType,
    isSubmittedAnswerAvailable = true,
    onSubmitVideoAssist,
    onLocalUserInputChange,
  }: ProblemScoringFooterProps) => {
    const [openDrawer, setOpenDrawer] = useState(false)
    const { isMobile } = useStudentAppMediaQuery()
    const { pathname } = useLocation()

    const handleLocalUserInputChange = useCallback(
      (localUserInput: RequestScoring.자동채점 | RequestScoring.일반채점) => {
        if (!onLocalUserInputChange) return
        onLocalUserInputChange(localUserInput)
      },
      [onLocalUserInputChange],
    )

    const handleDebouncedLocalUserInputChange = useMemo(() => {
      return debounce(handleLocalUserInputChange, 1000)
    }, [handleLocalUserInputChange])

    const footerView = useMemo(() => {
      const result = {
        isDrawerButtonView: false,
        isAnswerInputView: false,
      }

      if (!viewOption) return result

      const isSubmitted = problemScoring.isSubmitted
      const isAutoScoring = problemScoring.isAutoScoring

      // 자기주도학습은 studentAppSetting를 따지지 않고 제출 여부만 판단
      if (!viewOption.studentAppSetting) {
        result.isDrawerButtonView = isSubmitted
        result.isAnswerInputView = !isSubmitted
        return result
      }

      // prettier-ignore
      const 일반채점_채점전_문풀동공개 = !isSubmitted && !isAutoScoring && viewOption.studentAppSetting?.채점전문풀동공개 && !viewOption.studentAppSetting?.일반채점_채점전정답공개
      // prettier-ignore
      const 채점후_문풀동_정답해설공개 =
        isSubmitted && 
      ((!isSubmittedAnswerAvailable && (viewOption.studentAppSetting?.채점후문풀동공개 || viewOption.studentAppSetting?.채점후정답해설공개))
      || isSubmittedAnswerAvailable)

      const 채점후_자동채점 = isSubmitted && isAutoScoring

      result.isDrawerButtonView =
        일반채점_채점전_문풀동공개 || 채점후_문풀동_정답해설공개 || 채점후_자동채점
      result.isAnswerInputView = !isSubmitted && !viewOption.채점불가능

      return result
    }, [
      problemScoring.isSubmitted,
      problemScoring.isAutoScoring,
      viewOption,
      isSubmittedAnswerAvailable,
    ])

    if (!viewOption) return <></>

    if (!footerView.isAnswerInputView && !footerView.isDrawerButtonView) return <></>

    if (footerView.isDrawerButtonView) {
      const drawerStatus = (() => {
        const isMAAT = viewOption.content.type === 'MAAT'
        const isSubmitted = problemScoring.isSubmitted
        const 자기주도학습 = !viewOption.studentAppSetting

        const 정답해설공개 =
          !isMAAT &&
          ((isSubmitted && viewOption.studentAppSetting?.채점후정답해설공개) ||
            (!isSubmitted && viewOption.studentAppSetting?.일반채점_채점전정답공개))

        const 문풀동공개 =
          !isMAAT &&
          ((isSubmitted && viewOption.studentAppSetting?.채점후문풀동공개) ||
            (!isSubmitted && viewOption.studentAppSetting?.채점전문풀동공개))

        const 필기 = isSubmittedAnswerAvailable && problemScoring.isSubmitted

        const drawerButtonText = () => {
          return (
            <>
              {(자기주도학습 || (!자기주도학습 && 문풀동공개)) && (
                <span className="explation-submitted-answer-text">풀이동영상</span>
              )}
              {(자기주도학습 || (!자기주도학습 && 필기)) && (
                <span className="explation-submitted-answer-text">내풀이</span>
              )}
              {(자기주도학습 || (!자기주도학습 && 정답해설공개)) &&
                !!problemScoring.문제해설이미지 && (
                  <span className="explation-submitted-answer-text">해설</span>
                )}
            </>
          )
        }

        return {
          drawerAvailable:
            viewOption.content.type !== 'CONCEPTUAL' &&
            (정답해설공개 || 문풀동공개 || 필기 || 자기주도학습),
          drawerText: drawerButtonText(),
        }
      })()

      // 자기주도(챌린지, 오답심화)가 아닐 때
      if (viewOption.studentAppSetting && !problemScoring.문제해설이미지) return <></>
      return (
        <S.ProblemScoringFooter
          data-tooltip-id={`submitted-answer-${problemScoring.id}`}
          onClick={() => {
            drawerStatus.drawerAvailable && setOpenDrawer((prev) => !prev)
          }}
          className="has-padding"
        >
          {!!problemScoring.제출한답 && (
            <span
              className="left"
              onClick={(e) => {
                e.stopPropagation()
                if (isMobile) {
                  modalService.openModal(
                    <ProblemScoringStyle.MobileModalContainer>
                      <SubmittedAnswer
                        채점결과={problemScoring.채점결과}
                        제출한답={problemScoring.제출한답}
                        문제정답타입={problemScoring.문제정답타입}
                        ellipse={false}
                      />
                    </ProblemScoringStyle.MobileModalContainer>,
                    { modalName: '제출한 답 모달', hasCloseButton: true },
                  )
                } else {
                  CustomEventService.tooltipOn.dispatch(`submitted-answer-${problemScoring.id}`, e)
                }
              }}
            >
              <span className="answer-text">내가 쓴 답 :&nbsp;</span>

              <채점결과_말풍선
                id={`submitted-answer-${problemScoring.id}`}
                value={problemScoring.채점결과}
                문제정답타입={problemScoring.문제정답타입}
                제출한답={problemScoring.제출한답}
              />
              <SubmittedAnswer
                채점결과={problemScoring.채점결과}
                제출한답={problemScoring.제출한답}
                문제정답타입={problemScoring.문제정답타입}
              />
            </span>
          )}

          {problemScoring.isAutoScoring &&
            problemScoring.제출한답 === null &&
            viewOption.studentAppSetting && (
              <div className="left">
                <span className="answer-guide">선생님이 채점함</span>
              </div>
            )}

          {drawerStatus.drawerAvailable && (
            <>
              <span className="right">
                {drawerStatus.drawerText}
                <Icon name="icon_chevron_right" color={colors.gray.$500} size={20} />
              </span>
              <AnswerVideoDrawer
                contentId={contentId}
                isSubmittedAnswerAvailable={isSubmittedAnswerAvailable}
                viewOption={viewOption}
                openDrawer={openDrawer}
                problemScoring={problemScoring}
                onSubmitVideoAssist={onSubmitVideoAssist}
              />
            </>
          )}
        </S.ProblemScoringFooter>
      )
    }
    // 문제 : 제출 전
    // 학습지 or 교재 : 채점 가능 상태
    if (footerView.isAnswerInputView) {
      const answerDataTracking = () => {
        const trackingInfo = (() => {
          if (viewOption.content.kind === 'WORKSHEET' && viewOption.content.type === 'CUSTOM') {
            const isScoringPage = pathname.includes('/scoring')
            return {
              trackKey: !isScoringPage
                ? '[내학습지] 한문제씩_정답입력'
                : '[내학습지] 빠른채점_정답입력',
              trackData: {
                '학제/학년': problemScoring.학제학년학기,
              },
            }
          }
          if (viewOption.content.kind === 'WORKBOOK') {
            if (viewOption.content.type === 'CUSTOM') {
              return {
                trackKey: '[내교재] 빠른채점_정답입력',
                trackData: {
                  '학년/학기': problemScoring.학제학년학기,
                },
              }
            }
            if (viewOption.content.type === 'CUSTOM_SIGNATURE') {
              return {
                trackKey: '[시그니처교재] 빠른채점_정답입력',
                trackData: {
                  '학년/학기': problemScoring.학제학년학기,
                },
              }
            }
          }
          return null
        })()

        if (trackingInfo) {
          const 문제타입 =
            problemScoring.문제정답타입 === 'SINGLE_CHOICE' ||
            problemScoring.문제정답타입 === 'MULTIPLE_CHOICE'
              ? '객관식'
              : '주관식'
          window.freshpaint?.track(trackingInfo.trackKey, {
            문제타입,
            ...trackingInfo.trackData,
          })
        }
      }
      // 1. 자동채점
      // 1-1. 주관식
      if (problemScoring.localUserInput instanceof RequestScoring.자동채점) {
        if (problemScoring.문제정답타입 === 'SHORT_ANSWER') {
          const virtualKeyboardMarginBottom =
            isMobile && viewOption.content.type === 'CONCEPTUAL' ? 10 : undefined

          return (
            <S.ProblemScoringFooter className="has-padding">
              <자동채점입력.주관식
                id={problemScoring.id}
                keypadTypes={problemScoring.keypadTypes}
                answerUnits={problemScoring.answerUnits}
                value={problemScoring.localUserInput.localUserAnswer ?? ''}
                onChange={(v) => {
                  const currentAnswer = (problemScoring.localUserInput as RequestScoring.자동채점)
                    .localUserAnswer
                  problemScoring.setLocalUserInput(v)
                  const newLocalUserInput = problemScoring.localUserInput as RequestScoring.자동채점
                  const newAnswer = newLocalUserInput.localUserAnswer

                  if (currentAnswer === null && newAnswer === '') {
                    // 답 입력하지 않았지만 onChange 실행되는 케이스는 무시
                    return
                  }

                  if (currentAnswer !== newAnswer) {
                    handleDebouncedLocalUserInputChange(newLocalUserInput)
                  }
                }}
                onCloseCallback={answerDataTracking}
                virtualKeybaordShowType={virtualKeybaordShowType}
                virtualKeyboardMarginBottom={virtualKeyboardMarginBottom}
              />
            </S.ProblemScoringFooter>
          )
        }

        if (problemScoring.문제n지선다) {
          // 1-2. 객관식
          const data = Array.from({ length: problemScoring.문제n지선다 }).map((_, index) => ({
            label: index + 1,
            value: index + 1,
          }))

          return (
            <S.ProblemScoringFooter className="has-padding">
              <자동채점입력.객관식
                circleClassName={viewOption.content.type === 'CONCEPTUAL' ? 'circle-sm' : undefined}
                data={data}
                values={
                  problemScoring.localUserInput.localUserAnswer === ''
                    ? []
                    : problemScoring.localUserInput.localUserAnswer?.split(',') ?? []
                }
                max={problemScoring.문제정답길이}
                onChange={(v) => {
                  problemScoring.setLocalUserInput(v.join(','))
                  const newLocalUserInput = problemScoring.localUserInput as RequestScoring.자동채점
                  handleLocalUserInputChange(newLocalUserInput)
                  answerDataTracking()
                }}
              />
            </S.ProblemScoringFooter>
          )
        } else {
          console.error('문제n지선다 값이 없습니다.')
          return null
        }
      } else {
        // 2. 일반 채점
        return (
          <S.ProblemScoringFooter>
            <일반채점입력
              value={problemScoring.localUserInput?.localResult ?? ANSWER_TYPE.NONE}
              onChange={(v) => {
                problemScoring.setLocalUserInput(v)
                const newLocalUserInput = problemScoring.localUserInput as RequestScoring.일반채점
                handleLocalUserInputChange(newLocalUserInput)
                answerDataTracking()
              }}
            />
          </S.ProblemScoringFooter>
        )
      }
    }
  },
)

const S = {
  ProblemScoringFooter: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 70px;
    background-color: white;
    border-bottom-left-radius: var(--Radius-300);
    border-bottom-right-radius: var(--Radius-300);
    ${typo.body02};

    > * {
      cursor: pointer;
    }
    &.has-padding {
      padding: 0 20px;
    }

    > .left,
    > .center {
      display: flex;
      align-items: center;
      width: 100%;
      height: 36px;

      font-weight: bold;
      color: ${colors.gray.$700};
      .answer-text {
        flex-shrink: 0;
      }

      .answer-guide {
        color: ${colors.gray.$600};
        font-weight: 400;
        ${typo.body01}
      }
    }

    > .center {
      justify-content: center;
    }

    > .right {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      height: 36px;
      margin: 0 auto;
      cursor: pointer;

      color: ${colors.gray.$600};
    }
    .explation-submitted-answer-text + .explation-submitted-answer-text {
      &::before {
        content: '・';
      }
    }
  `,
  Thumbnail: styled.div`
    width: 100%;
    height: 100%;
    background: url(${s3URL.common('images/video/problem-solving/background.png')}) #fff;

    > .img-bg {
      position: absolute;
      top: 7%;
      left: 30%;
      padding: 4% 4% 0px 4%;

      width: 64%;
      height: 93%;
      background: #fff;
      box-shadow: 0px 4px 30px 0px #0000001a;
    }

    > .gradation {
      position: absolute;
      top: 50%;

      width: 100%;
      height: 50%;
      background: linear-gradient(180deg, rgba(251, 252, 255, 0) 0%, #fbfcff 72.4%);

      > .title {
        position: absolute;
        top: 40%;
        left: 7%;
      }
      > .sub-title {
        position: absolute;
        top: 20%;
        left: 7%;
      }
    }
  `,
}

export default ProblemScoringFooter
