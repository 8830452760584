import type { ISODate } from '@mathflat/shared/@types/date'
import type { EmptyString } from '@mathflat/shared/@types/string'
import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'

import type { ProblemDomain } from '~/@entities'
import { LEVEL_KOR } from '~/@entities/(Content)/constants'
import type { Entity as WorksheetProblemEntity } from '~/@entities/(Content)/Worksheet/WorksheetProblem/dto'
import { ANSWER_TYPE } from '~/@entities/Problem/constants'
import type { Entity as ProblemEntity } from '~/@entities/Problem/dto'
import type { AllGradeSubject } from '~/@entities/SchoolSystem/schema'
import type { SchoolType } from '~/@entities/SchoolSystem/schema'

export interface StudentWorksheetScoringParams<T extends '일반' | '시험지' = '일반' | '시험지'> {
  worksheet: {
    autoScorable: boolean
    label: string // 문항 정보
    school?: SchoolType
    grade?: AllGradeSubject
  }
  problem: ProblemEntity.Problem
  scoring: T extends '일반'
    ? {
        result: ValueOf<typeof ANSWER_TYPE>
        updateDatetime?: ISODate
        userAnswer: null | string | EmptyString
        worksheetProblemId: Exclude<
          WorksheetProblemEntity.WorksheetProblem,
          'ExamWorksheetProblem'
        >['id']
      }
    : {
        result: ValueOf<typeof ANSWER_TYPE>
        updateDatetime?: ISODate
        userAnswer: null | string | EmptyString
        examProblemId: WorksheetProblemEntity.ExamWorksheetProblem['id']
      }
}

export namespace Entity {
  export class StudentWorksheetScoring<T extends '일반' | '시험지' = '일반' | '시험지'> {
    id: T extends '일반'
      ? StudentWorksheetScoringParams<'일반'>['scoring']['worksheetProblemId']
      : StudentWorksheetScoringParams<'시험지'>['scoring']['examProblemId']
    userAnswer: null | string = null
    result: ProblemDomain.ScoringResult
    updateDatetime?: Date

    idName!: T extends '일반'
      ? Extract<keyof StudentWorksheetScoringParams<'일반'>['scoring'], 'worksheetProblemId'>
      : Extract<keyof StudentWorksheetScoringParams<'시험지'>['scoring'], 'examProblemId'>

    private _worksheet: StudentWorksheetScoringParams<T>['worksheet']
    private _problem: StudentWorksheetScoringParams<T>['problem']

    constructor({ worksheet, problem, scoring }: StudentWorksheetScoringParams<T>) {
      this._worksheet = worksheet
      this._problem = problem
      // scoring
      if ('worksheetProblemId' in scoring) {
        this.id = scoring.worksheetProblemId
        ;(this as StudentWorksheetScoring<'일반'>).idName = 'worksheetProblemId'
      } else {
        this.id = scoring.examProblemId
        ;(this as StudentWorksheetScoring<'시험지'>).idName = 'examProblemId'
      }
      this.result = scoring.result

      // userAnswer 이슈 : 빈 스트링일 경우 데이터적으로는 유저가 빈 스트링을 입력(자동채점)했을 가능성 혹은 선생님이 채점했을 경우의 상태를 공유한다.
      // 따라서 프론트/백엔드에서 유저가 빈 스트링으로 채점했을 경우를 막아야하는데, 백엔드에서는 막고 있지 않아 프론트에서 잘 막아야 한다.
      this.userAnswer = scoring.userAnswer === '' ? null : scoring.userAnswer

      if (scoring.updateDatetime) {
        this.updateDatetime = new Date(scoring.updateDatetime)
      }
    }

    get problem() {
      return this._problem
    }

    get 채점결과() {
      return this.result
    }

    get 문제번호() {
      return this._worksheet.label
    }

    get 문제정답() {
      return this._problem.answer
    }

    get 문제이미지() {
      return this._problem.problemImageUrl
    }

    get 문제정답이미지() {
      return this._problem.answerImageUrl
    }

    get 문제정답타입() {
      return this._problem.type
    }

    get 문제해설이미지() {
      if ('solutionImageUrl' in this._problem) {
        return this._problem.solutionImageUrl
      }
    }

    get 문제n지선다() {
      return this._problem.optionCount
    }

    get 제출한답() {
      return this.userAnswer
    }

    get isSubmitted() {
      return this.채점결과 !== ANSWER_TYPE.NONE
    }

    get isAutoScorable() {
      return this._worksheet.autoScorable
    }

    get 문제정답길이() {
      return this.문제정답.split(',').length
    }

    get 오답_모르는문제여부() {
      return this.채점결과 === ANSWER_TYPE.WRONG || this.채점결과 === ANSWER_TYPE.UNKNOWN
    }

    get keypadTypes() {
      return this._problem.keypadTypes
    }

    get answerUnits() {
      return this._problem.answerUnits
    }

    get 난이도() {
      return LEVEL_KOR[this._problem.level as keyof typeof LEVEL_KOR]
    }

    get 학제학년학기() {
      if (this._worksheet.school === 'HIGH') {
        return `${this._worksheet.grade}`
      } else {
        const _schoolType = this._worksheet.school === 'MIDDLE' ? '중' : '초'
        return _schoolType + this._worksheet.grade
      }
    }
  }
}
