import styled from '@emotion/styled'
import { observer } from 'mobx-react'
import type { ReactNode } from 'react'
import { generatePath, useLocation } from 'react-router'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { routeName } from '~/@common/constants'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import { useLastLocationManager } from '~/@common/services/(LastLocationManager)'
import modalService from '~/@common/services/modal.service'
import { colors, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { Icon } from '~/@common/ui/Icon/Icon'
import { PortalRootLayoutHeader } from '~/@common/ui/PortalRootLayoutHeader'
import GlobalHeader from '~/@pages/@widgets/(Navigation)/GlobalHeader/GlobalHeader'
import { StudentLearningContentBackConfirmModal } from '~/@pages/student/@widgets/learningContent/StudentLearningContentBackConfirmModal'

import { StudentWorksheetDetailPageService } from '../../@service/StudentWorksheetDetailPage.service'

interface Props {
  title?: ReactNode
  enableSlotRight?: boolean
}

export const StudentWorksheetHeader = observer(({ title }: Props) => {
  const { studentWorksheetId } = useParams<{ studentWorksheetId: string }>()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const locationManager = useLastLocationManager()

  const service = useRepository([StudentWorksheetDetailPageService, studentWorksheetId!])

  const isScoringPage = pathname.includes('/scoring')
  const defaultPrevRouteName = routeName.student.studentWorksheet

  const navigateBack = () => {
    if (!locationManager.prevState?.location.pathname) {
      navigate(defaultPrevRouteName)
      return
    }
    navigate(-1)
  }

  return (
    <PortalRootLayoutHeader>
      <GlobalHeader
        SlotCenter={title}
        SlotRight={
          isScoringPage ? (
            <Link to={generatePath(routeName.student.studentWorksheetById, { studentWorksheetId })}>
              <S.RightLink>
                <Icon name="icon_description_paper" size={20} color={colors.gray.$900} />
                <span>한문제씩</span>
              </S.RightLink>
            </Link>
          ) : (
            <Link
              to={generatePath(routeName.student.studentWorksheetScoringById, {
                studentWorksheetId,
              })}
            >
              <S.RightLink>
                <Icon name="icon_list" size={20} color={colors.gray.$900} />
                <span>빠른채점</span>
              </S.RightLink>
            </Link>
          )
        }
        prevLinkProps={{
          to: defaultPrevRouteName,
          onClick: (e) => {
            e.preventDefault()

            if (service.problemScoringViewOption?.채점불가능) {
              navigateBack()
              return
            }

            if (service.problemScoringColl?.toScoredArr.length) {
              modalService.openModal(
                <StudentLearningContentBackConfirmModal
                  defaultPrevRouteName={defaultPrevRouteName}
                  onSubmit={async () => {
                    if (!studentWorksheetId) {
                      return
                    }
                    try {
                      modalService.showLoader()
                      modalService.closeModal()

                      await service.onSubmitWorksheetProblems(studentWorksheetId)
                    } catch (error) {
                      errorHandlerService.handle(error)
                    } finally {
                      modalService.hideLoader()
                    }
                  }}
                />,
                {
                  modalName: '답안제출_안내',
                },
              )
              return
            }
            navigateBack()
          },
        }}
      />
    </PortalRootLayoutHeader>
  )
})

const S = {
  RightLink: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    ${typo.caption01};
    color: ${colors.gray.$900};
    font-weight: bold;

    cursor: pointer;

    ${mediaQuery.underDesktop} {
      ${typo.body02};
      font-weight: 400;
      color: ${colors.gray.$600};
      svg {
        color: ${colors.gray.$600};
      }
    }
  `,
}
