import * as S from '@effect/schema/Schema'
import { NullableNumber, NullableString } from '@mathflat/shared/@common/schema/schema'

import { StudentDomain } from '~/@entities/Student/domain.ts'

import { ContentLevel } from './(Content)/module'

// TODO: 정리하려면 시간 걸리니 일단 스키마를 이 파일에 다 때려넣고 나중에 정리하자
export module CommonDomain {
  export const STATUS = {
    INCOMPLETE: 'INCOMPLETE',
    COMPLETE: 'COMPLETE',
    PROGRESS: 'PROGRESS',
  } as const

  export const Video = S.struct({
    id: S.number,
    thumbnailUrl: S.string,
    // thumbnailUrl: S.string.pipe(S.nullable),

    videoUrl: S.string,
  })

  export const Status = S.enums(STATUS)
  export type Status = S.Schema.To<typeof Status>
}
export module CurriculumDomain {
  export const TYPE = {
    REVISION: 'REVISION',
    SCHOOL: 'SCHOOL',
    GRADE: 'GRADE',
    SEMESTER: 'SEMESTER',
    SUBJECT: 'SUBJECT',
    BIG_CHAPTER: 'BIG_CHAPTER',
    MIDDLE_CHAPTER: 'MIDDLE_CHAPTER',
    LITTLE_CHAPTER: 'LITTLE_CHAPTER',
    CONCEPT: 'CONCEPT',
  } as const

  export const OUT_OF_CURRICULUM_TYPE = {
    교육과정내: 'CURRICULUM',
    교육과정외: 'EXTRA_CURRICULUM',
    이전교육과정: 'PREVIOUS_GRADE',
  } as const

  export const CURRICULUM_CONCEPT_TYPE = {
    소단원: 'LITTLE_CHAPTER',
    중단원: 'MIDDLE_CHAPTER',
  } as const

  export const conceptType = S.enums(CURRICULUM_CONCEPT_TYPE)

  export const OutOfCurriculumType = S.enums(OUT_OF_CURRICULUM_TYPE)

  /**
   * 교육과정 종류
   * <pre>
   *   REVISION: 개정
   *   SCHOOL: 학교급
   *   GRADE: 학년
   *   SEMESTER: 학기
   *   SUBJECT: 과목
   *   BIG_CHAPTER: 대단원
   *   MIDDLE_CHAPTER: 중단원
   *   MIDDLE_CHAPTER: 소단원
   * </pre>
   */
  export const Type = S.enums(TYPE)

  export const Curriculum = S.struct({
    type: Type,
    id: S.number,
    name: S.string,
    hidden: S.boolean,

    revisionId: S.number,
    revisionName: S.string,
    schoolId: S.number,
    schoolName: S.string,
    gradeId: S.number,
    gradeName: S.string,
    semesterId: S.number,
    semesterName: S.string,
    subjectId: S.number.pipe(S.nullable),
    subjectName: S.string.pipe(S.nullable),
    bigChapterId: S.number,
    bigChapterName: S.string,
    middleChapterId: NullableNumber,
    middleChapterName: NullableString,
    littleChapterId: NullableNumber,
    littleChapterName: NullableString,
  })
}
export module AnswerDomain {
  export const Unit = S.struct({
    unit: S.string,
    index: S.number,
  })
}

// 문제 도메인
export module ProblemDomain {
  export const TYPE = {
    단답형: 'SINGLE_CHOICE',
    선다형: 'MULTIPLE_CHOICE',
    주관식: 'SHORT_ANSWER',
    서술형: 'ESSAY',
  } as const

  export const AUTO_SCORED_TYPE = {
    불가능: 'IMPOSSIBLE',
    가능_0포함양수: 'POSSIBLE_PLUS',
    가능_음수: 'POSSIBLE_MINUS',
    가능_단위: 'POSSIBLE_UNIT',
    가능_분수: 'POSSIBLE_FRACTION',
    가능_소수: 'POSSIBLE_DECIMAL',
    가능_연산필요분수: 'CALCULATION_FRACTION',
    미확인: 'UNIDENTIFIED',
  } as const

  export const GROUP_CASE = {
    SUB_TOPIC: 'SUB_TOPIC',
    CONCEPT: 'CONCEPT',
    SUB_TOPIC_SPECIAL: 'SUB_TOPIC_SPECIAL',
    TOPIC: 'TOPIC',
  } as const

  export const SCORING_RESULT = {
    정답: 'CORRECT',
    오답: 'WRONG',
    모름: 'UNKNOWN',
    안품: 'NONE',
  } as const

  export const KEYPAD_TYPES = {
    DECIMAL: 'DECIMAL',
    FRACTION: 'FRACTION',
    MIXED_FRACTION: 'MIXED_FRACTION',
  } as const

  // CustomWorkbook이 아닌 교재의 문제의 경우 저작권 이슈로 인해 ProblemId가 null일 수 있음.
  // 이 경우 workbookProblemId를 통해 해당 문제를 특정하고 정보만 가져와서 사용함.
  // 시중교재, 교과서가 아닌 경우 null이 안나옴.
  export const IdInTheWorkbook = S.nullable(S.number)

  export const ScoringResult = S.enums(SCORING_RESULT)
  export type ScoringResult = S.Schema.To<typeof ScoringResult>

  export const Type = S.enums(TYPE)
  export type Type = S.Schema.To<typeof Type>

  export const AutoScoredType = S.enums(AUTO_SCORED_TYPE)
  export const groupCase = S.enums(GROUP_CASE)
  export const KeypadTypes = S.enums(KEYPAD_TYPES)

  export const Problem = S.struct({
    id: S.number,
    type: Type,
    conceptId: S.number,
    groupCode: S.number.pipe(S.nullable),
    topicId: S.number.pipe(S.nullable),
    subTopicId: S.number.pipe(S.nullable),
    groupCase,
    optionCount: S.number,
    level: ContentLevel,
    problemImageUrl: S.string,
    answerImageUrl: S.string,
    solutionImageUrl: S.string,
    answer: S.string,
    answerUnits: S.array(AnswerDomain.Unit),
    autoScoredType: AutoScoredType,
    autoScored: S.boolean,
    hidden: S.boolean,
    trendy: S.boolean,
    keypadTypes: S.array(KeypadTypes),
    levelOfConceptChip: S.any,
  })

  export type Problem = S.Schema.To<typeof Problem>

  export const Summary = S.struct({
    problemId: S.number,
    totalUsed: S.number,
    correctTimes: S.number,
    wrongTimes: S.number,
    answerRate: S.number,
  })
}

// 자기주도학습
export module LearningProcessDomain {
  export const Problem = S.extend(
    ProblemDomain.Problem,
    S.struct({
      problemSummary: ProblemDomain.Summary,
      video: CommonDomain.Video.pipe(S.nullable),
    }),
  )

  export const AssignedWorksheet = S.struct({
    studentWorksheetId: S.number,
    studentName: S.string,
    studentId: StudentDomain.Id,
    status: CommonDomain.Status,
  })

  export const Scoring = S.struct({
    studentWorksheetScoring: S.struct({
      worksheetProblemId: S.number,
      result: ProblemDomain.ScoringResult,
      userAnswer: S.string.pipe(S.nullable),
    }),
    worksheetProblem: S.struct({
      index: S.number,
      tagTop: S.string,
    }),
    problem: Problem,
  })
}

export module WorksheetDomain {
  export const SELF_LEARNING_TYPE = {
    심화학습: 'LEVEL_UP',
    오답유형학습: 'WRONG_CONCEPT',
  } as const

  export const TYPE = {
    CUSTOM: 'CUSTOM',
    내신대비추천: 'RECOMMEND',
    모의고사: 'PRACTICE_TEST',
    연산: 'ARITHMETIC',
    입학테스트: 'ENTRANCE',
    WEEKLY: 'WEEKLY',
    단원테스트: 'CHAPTER',
    내신대비교과서: 'SCHOOL_PREPARE_TEXTBOOK',
    수능모의고사: 'PRACTICE_TEST_PAIR',
    시험: 'EXAM',
    MAAT: 'MAAT',
    수능특강: 'SAT_LECTURE',
    챌린지: 'CHALLENGE',
    자기주도학습: 'SELF_LEARNING',
    CONCEPTUAL: 'CONCEPTUAL',
  } as const

  export const TAG = {
    기본: 'BASIC',
    연습문제: 'PRACTICE',
    숙제: 'HOMEWORK',
    일일TEST: 'DAILY_TEST',
    주간TEST: 'WEEKLY_TEST',
    단원TEST: 'CHAPTER_TEST',
    입학TEST: 'ENTRANCE_TEST',
    총괄TEST: 'GENERAL_TEST',
    수능대비: 'CSA_TEST',
    내신대비: 'SCHOOL_PREPARE',
    모의고사: 'PRACTICE_TEST',
    단원별취약: 'WEAK_CHAPTER',
    기간별오답: 'WEAK_DATE',
    학습지오답: 'WEAK_WORKSHEET',
    교재오답: 'WEAK_WORKBOOK',
    모의고사쌍둥이: 'PRACTICE_TEST_PAIR',
    연산: 'ARITHMETIC',
    기타: 'ETC',
    유형칩: 'CONCEPT_CHIP',
  } as const

  export const EXAM_TYPE = {
    MAAT: 'MAAT',
    EXAM: 'MIDDLE_SCHOOL_ACHIEVEMENT_EVALUATION',
  } as const

  export const ACCESS_MODIFIER_TO_STUDENT = {
    PUBLIC: 'PUBLIC',
    PRIVATE: 'PRIVATE',
  } as const

  // 보충학습 타입 (무조건 학습지에 속함)
  export const SelfLearningType = S.enums(SELF_LEARNING_TYPE)
  export type SelfLearningType = S.Schema.To<typeof SelfLearningType>

  export const AccessModifierToStudent = S.enums(ACCESS_MODIFIER_TO_STUDENT)
  export const Level = S.literal(1, 2, 3, 4, 5)
  export const Type = S.enums(TYPE)
  export const Tag = S.enums(TAG)
}
