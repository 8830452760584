import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { WorksheetDomain } from '@mathflat/domain/@entities'
import type { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import {
  HandwrittenNoteControllerService,
  HandwrittenNoteModeService,
  type HandwrittenNoteTypes,
  ReadOnlyHandwrittenNote,
  WritableHandwrittenNote,
} from '@mathflat/handwritten-note'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import {
  type ReactEventHandler,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { isMobile as isMobileDevice } from 'react-device-detect'

import { handwrittenNoteApi, HandwrittenNoteType } from '~/@common/api/handwrittenNoteApi'
import { useIntersectionObserver } from '~/@common/hooks/useIntersectionObserver'
import { useRepository } from '~/@common/hooks/useRepository'
import { errorHandlerService } from '~/@common/services'
import { IndexedDbService } from '~/@common/services/indexedDb.service'
import { monitoringService } from '~/@common/services/monitoring.service'
import { commonRepo } from '~/@common/services/repo.service.ts'
import { colors, textEllipsis, typo } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import type { Size } from '~/@common/utils/domUtils'
import type { ProblemScoring } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import { NoteScaleResetButton } from '~/@pages/@common/NoteScaleResetButton'
import HandwrittenNoteMinimalToolbar from '~/@pages/student/@widgets/handwritten-note/HandwrittenNoteMinimalToolbar.tsx'
import HandwrittenNoteToolbar from '~/@pages/student/@widgets/handwritten-note/HandwrittenNoteToolbar'
import { VerticalDivider } from '~/@pages/student/@widgets/VerticalDivider'
import { useLearningProcessReference } from '~/@pages/student/learning-process/@common/hooks/useGetLearningProcessReference.ts'
import { REFERENCE_TYPE_STUDENT_WORKSHEET } from '~/@pages/student/learning-process/@widgets/service/LearningProcess.service.ts'

import type { WorksheetScoringByOneProps } from '../WorksheetScoringByOne'
import WorksheetScoringByOneBodyFooter from './(WorksheetScoringByOneSlideFooter)/WorksheetScoringByOneBodyFooter'

type NoteData = HandwrittenNoteTypes.NoteData

//TODO: 미제출학습지에서 contentId넘겨야함
/**
 * **StudentLearningContentsProblem**
 * @param titleIngredients -
 * 제목을 구성하는 요소들을 넣으면 제목이 생성됩니다.
 * (해당 요소가 없다면 자동으로 생략)
 * @param isLastProblem - 마지막 문제인지 여부
 * @param isSubmittable - 제출 가능한지 여부
 * @param problemScoring - 문제 채점 정보 (이 컴포넌트에서는 **학습지 채점**에만 해당)
 * @param onNextOrSubmit - 다음 문제로 넘어가거나 제출하기 버튼을 눌렀을 때 실행되는 함수
 */
type WorksheetScoringByOneSlideProps = {
  titleIngredients: string[]
  isLastProblem: boolean
  isSubmittable: boolean
  problemScoring: ProblemScoring<'WORKSHEET'>
  problemsCount?: number
  disableWritting?: boolean
  type: WorksheetEntity.Worksheet['type']
  contentId: string
  footerNode?: ReactNode
  onNextOrSubmit: () => void
} & Pick<
  WorksheetScoringByOneProps<'출제' | '미출제'>,
  'containerTopNode' | 'viewOption' | 'isLastScreen' | 'onLocalUserInputChange'
>
export const WorksheetScoringByOneSlide = observer(
  ({
    titleIngredients,
    isLastProblem,
    isSubmittable,
    problemScoring,
    problemsCount,
    viewOption,
    onNextOrSubmit,
    isLastScreen = false,
    disableWritting = false,
    contentId,
    type,
    containerTopNode,
    footerNode,
    onLocalUserInputChange,
  }: WorksheetScoringByOneSlideProps) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const entry = useIntersectionObserver(containerRef, {
      freezeOnceVisible: false,
    })
    const referenceParam = useLearningProcessReference()
    const isVisible = !!entry?.isIntersecting
    // 실시간모니터링 일때만 필기 저장 방식이 server이므로
    const isLiveLesson = problemScoring.handwrittenNoteSaveStorage === 'SERVER'

    const [imageUrl, setImageUrl] = useState('')
    const [noteData, setNoteData] = useState<NoteData>()
    const [teacherNoteData, setTeacherNoteData] = useState<NoteData>()
    const [noteContentSize, setNoteContentSize] = useState<Size>()

    const [timestamp, setTimestamp] = useState<number>()
    const [isResetButtonDisplayed, setIsResetButtonDisplayed] = useState(false)

    const hasNoteData = useMemo(() => noteData || teacherNoteData, [noteData, teacherNoteData])

    const noteContainerRef = useRef<HTMLDivElement>(null)

    const resetNoteScale = () => {
      setTimestamp(Date.now())
      setIsResetButtonDisplayed(false)
    }

    const updateIsResetButtonDisplay = ({ changed }: HandwrittenNoteTypes.TransformEventProps) => {
      setIsResetButtonDisplayed(!changed)
    }

    const indexedDbService = useRepository(IndexedDbService)
    const handwrittenNoteModeService = useRepository(HandwrittenNoteModeService)

    const [noteControllerService] = useState(
      new HandwrittenNoteControllerService(handwrittenNoteModeService),
    )

    const title = titleIngredients.filter((title) => title).join(VerticalDivider)

    const handleImageLoad: ReactEventHandler<HTMLImageElement> = (e) => {
      const contentSize = {
        width: 1200,
        height: Math.max(729 - 40, e.currentTarget.height + 300),
      }
      setNoteContentSize(contentSize)
      setImageUrl(problemScoring.문제이미지!)
    }

    const handleImageError: ReactEventHandler<HTMLImageElement> = (e) => {
      e.currentTarget.onerror = null
    }
    // indexedDb용
    const noteReadFromIndexedDb = useCallback(async () => {
      try {
        const data = await indexedDbService.getStudentWorksheetNote({
          studentWorksheetId: +contentId,
          worksheetProblemId: problemScoring.id,
        })
        if (data?.noteData) {
          return data.noteData
        }
      } catch (err) {
        errorHandlerService.handle(err)
      }
    }, [indexedDbService, problemScoring.id, contentId])
    // indexedDb용
    const noteWriteToIndexedDb = useCallback(
      async (data: NoteData) => {
        try {
          await indexedDbService.putStudentWorksheetNote({
            studentWorksheetId: +contentId,
            worksheetProblemId: problemScoring.id,
            noteData: data,
            timestamp: new Date().getTime(),
          })
        } catch (err) {
          errorHandlerService.handle(err)
        }
      },
      [indexedDbService, problemScoring.id, contentId],
    )
    // 서버용 학생 노트 불러오기
    const noteReadFromServer = useCallback(async () => {
      if (problemScoring.isSubmitted) return
      try {
        // 하위호환성 코드 Start
        // indexedDb에 기존에 저장한 데이터가 있었다면 setState 하고 바로 server에 올림
        const indexedDbData = await indexedDbService.getStudentWorksheetNote({
          studentWorksheetId: +contentId,
          worksheetProblemId: problemScoring.id,
        })
        if (indexedDbData?.noteData) {
          await handwrittenNoteApi.uploadStudentWorksheetNotes(
            [
              {
                studentWorksheetId: +contentId,
                worksheetProblemId: problemScoring.id,
                noteData: indexedDbData.noteData,
                timestamp: new Date().getTime(),
              },
            ],
            HandwrittenNoteType.STUDENT_WORKSHEET_SCORING,
          )
          const _noteData = indexedDbData.noteData
          await indexedDbService.deleteStudentWorksheetNotes([
            {
              studentWorksheetId: +contentId,
              worksheetProblemId: problemScoring.id,
            },
          ])
          return _noteData
        }
        // 하위호환성 코드 End

        const data = await handwrittenNoteApi.fetchNote({
          id: +contentId,
          subId: problemScoring.id,
          type: HandwrittenNoteType.STUDENT_WORKSHEET_SCORING,
        })
        return data
      } catch (err) {
        errorHandlerService.handle(err)
      }
    }, [problemScoring.isSubmitted, problemScoring.id, indexedDbService, contentId])
    // 서버용
    const noteWriteToServer = useCallback(
      async (data: NoteData) => {
        try {
          handwrittenNoteApi.uploadStudentWorksheetNote(
            {
              studentWorksheetId: +contentId,
              worksheetProblemId: problemScoring.id,
              noteData: data,
              timestamp: new Date().getTime(),
            },
            HandwrittenNoteType.STUDENT_WORKSHEET_SCORING,
          )
          window.freshpaint?.track('필기 제출', { ...referenceParam })
          // 서버에 제출 후 indexedDb에 저장된 데이터 삭제
          await indexedDbService.deleteStudentWorksheetNotes([
            {
              studentWorksheetId: +contentId,
              worksheetProblemId: problemScoring.id,
            },
          ])
        } catch (err) {
          errorHandlerService.handle(err)
        }
      },
      [contentId, problemScoring.id, referenceParam, indexedDbService],
    )

    // 제출 전/후 polling으로 선생님의 필기 가져오기
    useEffect(() => {
      if (!isVisible || !isLiveLesson || !commonRepo.isSchoolflat) return
      const fetchTeacherNote = async () => {
        return await handwrittenNoteApi.fetchNote({
          id: +contentId,
          subId: problemScoring.id,
          type: HandwrittenNoteType.STUDENT_WORKSHEET_SCORING_TEACHER,
        })
      }

      const listener = monitoringService.listenMessage<
        Awaited<ReturnType<typeof fetchTeacherNote>>
      >({
        callback: fetchTeacherNote,
        onSuccess: (data) => {
          if (!data) return
          setTeacherNoteData(data)
        },
        onError: () => {
          listener.stop()
        },
      })

      return () => {
        listener.stop()
      }
    }, [
      problemScoring.handwrittenNoteSaveStorage,
      contentId,
      problemScoring.id,
      problemScoring.isSubmitted,
      isLiveLesson,
      isVisible,
    ])

    // 제출한 학생의 필기 가져오기
    useEffect(() => {
      if (!isVisible) return
      if (isLiveLesson && !problemScoring.isSubmitted) return
      if (!problemScoring.handwrittenNoteUrl) return
      handwrittenNoteApi
        .fetchNoteByUrl(problemScoring.handwrittenNoteUrl)
        .then((data) => {
          if (data) {
            setNoteData(data)
          }
        })
        .catch(errorHandlerService.handle)
    }, [
      isLiveLesson,
      problemScoring.isSubmitted,
      isVisible,
      problemScoring.handwrittenNoteUrl,
      problemScoring.handwrittenNoteSaveStorage,
    ])

    const problemImage = useMemo(() => {
      return <img src={imageUrl} width={344} alt="문제이미지" />
    }, [imageUrl])

    const isShowHandwrittenNoteToolbar =
      !problemScoring.isSubmitted && noteContentSize && !disableWritting

    const isAvailableAiTutor =
      referenceParam?.referenceType !== REFERENCE_TYPE_STUDENT_WORKSHEET &&
      commonRepo.Ai튜터_사용가능_여부

    return (
      <S.Container
        ref={containerRef}
        // swiper의 slidesPerView의 옵션이 문제 총 개수가 2개 이하와 초과일때가 달라 깨지는 style로 인해 나눠서 적용
        className={clsx(
          problemsCount !== undefined && problemsCount <= 2 ? 'fullWidth' : 'minWidth',
        )}
      >
        {isVisible && (
          <>
            {containerTopNode ?? (
              <div className="container__top">
                <strong className="top__problemIndex">{problemScoring.문제번호}</strong>
                {problemScoring.문제정답타입 === 'MULTIPLE_CHOICE' && (
                  <strong className="top__answerAmount">
                    정답 {problemScoring.문제정답길이}개
                  </strong>
                )}
                <strong
                  className="top__subjectSummary"
                  css={css`
                    ${textEllipsis(1)};
                  `}
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
                {isShowHandwrittenNoteToolbar && (
                  <div
                    className="top__toolbarContainer"
                    style={{
                      marginRight: type === WorksheetDomain.TYPE.자기주도학습 ? '160px' : '-12px',
                    }}
                  >
                    {(!isAvailableAiTutor || type !== WorksheetDomain.TYPE.자기주도학습) && (
                      <HandwrittenNoteToolbar
                        top={-31}
                        uiMode="simple"
                        controllerService={noteControllerService}
                      />
                    )}
                  </div>
                )}
              </div>
            )}

            <div className="container__bottom" ref={noteContainerRef}>
              {isShowHandwrittenNoteToolbar && isResetButtonDisplayed && (
                <NoteScaleResetButton
                  onClick={resetNoteScale}
                  style={{
                    position: 'absolute',
                    top: '16px',
                    right: '16px',
                  }}
                />
              )}
              {type === WorksheetDomain.TYPE.자기주도학습 &&
                noteContainerRef.current &&
                isAvailableAiTutor && (
                  <HandwrittenNoteMinimalToolbar
                    controllerService={noteControllerService}
                    containerEl={noteContainerRef.current}
                    containerPadding={20}
                    right={isResetButtonDisplayed ? 72 : 20}
                    top={16}
                  />
                )}
              <div className="problem-area">
                {problemScoring.isSubmitted ? (
                  hasNoteData ? (
                    <ReadOnlyHandwrittenNote
                      noteData={noteData}
                      readonlyNoteData={teacherNoteData}
                      renderPriority="readonlyNoteData"
                      isHidden={false}
                      preventScale={!isMobileDevice}
                      style={{
                        overflow: isMobileDevice ? 'hidden' : 'auto',
                      }}
                    >
                      {problemImage}
                    </ReadOnlyHandwrittenNote>
                  ) : (
                    problemImage
                  )
                ) : noteContentSize && !disableWritting ? (
                  <>
                    <WritableHandwrittenNote
                      key={timestamp}
                      contentSize={noteContentSize}
                      controllerService={noteControllerService}
                      readonlyNoteData={teacherNoteData}
                      renderPriority="readonlyNoteData"
                      readFunc={isLiveLesson ? noteReadFromServer : noteReadFromIndexedDb}
                      writeFunc={isLiveLesson ? noteWriteToServer : noteWriteToIndexedDb}
                      saveDelay={100}
                      preventOverflow={isMobileDevice}
                      onTransformChange={updateIsResetButtonDisplay}
                      style={{
                        paddingBottom: '20px',
                        backgroundColor: colors.gray.$500,
                        overflow: 'hidden',
                      }}
                    >
                      {problemImage}
                    </WritableHandwrittenNote>
                  </>
                ) : (
                  problemImage
                )}
                {problemScoring.문제이미지 && (
                  <img
                    src={problemScoring.문제이미지}
                    onError={handleImageError}
                    onLoad={handleImageLoad}
                    alt=""
                    css={_preloadImageCss}
                  />
                )}
              </div>
              <div className="swiper-lazy-preloader swiper-lazy-preloader-black" />
              {footerNode ?? (
                <WorksheetScoringByOneBodyFooter
                  type={type}
                  isLastProblem={isLastProblem}
                  isSubmittable={isSubmittable}
                  problemScoring={problemScoring}
                  onNextOrSubmit={onNextOrSubmit}
                  viewOption={viewOption}
                  isLastScreen={isLastScreen}
                  onLocalUserInputChange={onLocalUserInputChange}
                />
              )}
            </div>
          </>
        )}
      </S.Container>
    )
  },
)

const S = {
  Container: styled.section`
    display: flex;
    flex-direction: column;
    height: 100%;

    &.minWidth {
      min-width: 384px;
    }

    &.fullWidth {
      width: 100%;
    }

    .container__top {
      display: flex;
      align-items: center;
      flex: 0 0 70px;
      padding: 20px;
      padding-right: 32px;
      gap: 10px;
      border-top-right-radius: var(--Radius-300);
      border-top-left-radius: var(--Radius-300);
      min-width: 384px;
      background-color: ${colors.white};
      border-bottom: 1px solid ${colors.gray.$300};
      ${mediaQuery.underTablet} {
        padding: 0 20px;
        flex: 0 0 38px;
      }
    }

    .container__top-title {
      font-weight: 700;
      color: ${colors.gray.$900};
      ${typo.body01}
    }
    .container__top-guide {
      display: flex;
      gap: 10px;
      color: ${colors.gray.$800};
      ${typo.caption01}
    }
    .top__guid-round {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .top__subjectSummary {
      width: 100%;
      min-width: 30%;
      color: ${colors.gray.$700};
      ${typo.body02}
    }
    .top__problemIndex {
      min-width: max-content;
      color: ${colors.gray.$900};
      ${typo.body01}
    }
    .top__answerAmount {
      padding: 4px 12px;
      border-radius: var(--Radius-200);
      background-color: ${colors.gray.$200};
      color: ${colors.gray.$800};
      min-width: max-content;
      ${typo.body02}
    }
    .top__toolbarContainer {
      width: 340px;
      position: relative;
      flex-shrink: 0;
      margin-left: auto;
    }

    .container__bottom {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-bottom-right-radius: var(--Radius-300);
      border-bottom-left-radius: var(--Radius-300);
      padding: 20px;
      background-color: ${colors.white};
      flex: 1 1 100%;
      overflow: hidden;

      .ms__HandwrittenNoteMinimalToolbar {
        z-index: 10;
      }
    }

    .container__bottom-list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      row-gap: 25px;
      column-gap: 20px;
      overflow: scroll;
    }
    .problem-area {
      flex: 1 1 100%;
      overflow: auto;
    }
  `,
}
const _preloadImageCss = css`
  position: absolute;
  left: -999999px;
`
