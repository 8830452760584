import type {
  PollingCallback,
  PollingOptions,
} from '@mathflat/shared/@modules/connectorClient/connectorClient'
import { AxiosPollingClient } from '@mathflat/shared/@modules/connectorClient/connectorClient'

import maxios from '../utils/maxios'

class MonitoringService {
  // 추후 websocket으로 변경 예정
  private _connector: AxiosPollingClient
  constructor() {
    this._connector = new AxiosPollingClient(maxios)
  }
  listenMessage<T>(
    fn: PollingCallback<T>,
    options: PollingOptions = {
      intervalTime: 1000,
      executeImmediately: true,
    },
  ) {
    return this._connector.listenMessagePolling(fn, options)
  }
}

export const monitoringService = new MonitoringService()
