import { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import { Entity as StudentWorksheetEntity } from '@mathflat/domain/@entities/StudentWorksheet/dto'
import { Entity as StudentWorksheetScoringEntity } from '@mathflat/domain/@entities/StudentWorksheet/StudentWorksheetScoring/dto'
import { makeAutoObservable, runInAction } from 'mobx'

import { RequestScoring, studentWorksheetApi } from '~/@common/api'
import { toastService } from '~/@common/services'
import { commonRepo } from '~/@common/services/repo.service'
import { localStorageService } from '~/@common/services/storage.service'
import {
  ProblemScoring,
  ProblemScoringColl,
} from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import { ProblemScoringViewOption } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'

// idea
// interface WorksheetScoring<T extends '출제'|'미출제', U extends T extends '출제' ? '오답유형학습'|'오답유형학습아님':never> {
// problemScoringColl?: U extends '오답유형학습' ? ProblemScoringColl<'WORKSHEET'>[]:ProblemScoringColl<'WORKSHEET'>
// problemScoringViewOption?: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'>
// studentWorksheet?: T extends '출제' ? StudentWorksheetEntity.StudentWorksheet:undefined
// worksheet?: WorksheetEntity.CustomWorksheet
// }
//
// 출제의 경우
// StudentWorksheetDetailPageService extends WorksheetScoring<'출제'> {}
// 미출제의 경우
// WorksheetScoringPageService extends WorksheetScoring<'미출제'> {}
// 자기주도의 경우
// 자기주도Service {
// 문제풀이결과확인: WorksheetScoring<'출제'>의 구현체
// 오답유형학습: WorksheetScoring<'출제', '오답유형학습'>의 구현체
// }

// idea2
//

export class StudentWorksheetDetailPageService {
  problemScoringColl?: ProblemScoringColl<'WORKSHEET'>
  problemScoringViewOption?: ProblemScoringViewOption<'NOT_학습모듈'>
  studentWorksheet?: StudentWorksheetEntity.StudentWorksheet
  worksheet?: WorksheetEntity.CustomWorksheet
  get handwrittenNoteSaveStorage(): 'SERVER' | 'INDEXED_DB' {
    return 'SERVER'
  }
  constructor() {
    makeAutoObservable(this)
  }

  async loadAndSetStudentWorksheetScorings(
    studentWorksheetId: StudentWorksheetEntity.StudentWorksheet['id'],
  ) {
    const { worksheet, studentWorksheet } =
      await studentWorksheetApi.getAssignedStudentWorksheetById(studentWorksheetId)

    const answerHideFlag = false

    const problemScoringsParams = answerHideFlag
      ? {
          answerHideFlag: true,
        }
      : undefined

    const problemScorings = await studentWorksheetApi.getAssignedStudentWorksheetWithProblems(
      studentWorksheetId,
      problemScoringsParams,
    )
    const _scoringSaveStorage = worksheet?.autoScorable ? 'SERVER' : 'LOCAL_STORAGE'

    runInAction(async () => {
      this.worksheet = new WorksheetEntity.CustomWorksheet(worksheet)
      this.studentWorksheet = new StudentWorksheetEntity.StudentWorksheet(studentWorksheet)
      /**
       * start ::: 하위호환성용 코드
       * 가채점 도입되면서 로컬에 이미 입력한 정답이 있는 경우, 로컬에 입력한 모든 답을 가채점데이터로 제출 후 로컬에 입력한 답을 삭제
       * 모두 가채점으로 제출을 해야 모니터링에서 상태확인이 가능함
       */
      const _submitLocalUserAnswerToServerAndRemoveLocalStorageScoring = async () => {
        if (_scoringSaveStorage !== 'SERVER' || !localStorageService.scoring.size) return

        const _problemScorings = problemScorings
          .filter(({ worksheetProblemId }) => localStorageService.scoring.get(worksheetProblemId))
          .map(({ problem, worksheetProblemId, scoring }, index) => {
            return new RequestScoring.자동채점<'WORKSHEET'>({
              localUnknown: localStorageService.scoring.get(worksheetProblemId) === 'UNKNOWN',
              localUserAnswer: localStorageService.scoring.get(worksheetProblemId) ?? null,
              scoring: this._createScoring({
                worksheetProblemId,
                scoring,
                problem,
                index,
              }),
            })
          })

        if (_problemScorings.length) {
          window.freshpaint?.track('로컬 가채점 갯수', {
            count: _problemScorings.length,
          })
          await this.onVirtualScoringInit(studentWorksheetId, _problemScorings)
        }
      }

      await _submitLocalUserAnswerToServerAndRemoveLocalStorageScoring()
      /** end ::: 하위호환성용 코드 */

      const virtualScorings =
        await studentWorksheetApi.getVirtualAutoScoringAssignedStudentWorksheet([
          studentWorksheetId,
        ])
      this.problemScoringColl = new ProblemScoringColl<'WORKSHEET'>(
        problemScorings.map(
          ({ problem, worksheetProblemId, scoring, handwrittenNoteUrl }, index) =>
            new ProblemScoring<'WORKSHEET'>(
              {
                handwrittenNoteSaveStorage: this.handwrittenNoteSaveStorage,
                scoringSaveStorage: _scoringSaveStorage,
                scoring: this._createScoring({
                  worksheetProblemId,
                  scoring,
                  problem,
                  index,
                }),
              },
              worksheetProblemId,
              handwrittenNoteUrl,
              virtualScorings[0]?.virtualScores,
            ),
        ),
      )

      if (commonRepo.studentAppSetting) {
        this.problemScoringViewOption = new ProblemScoringViewOption({
          content: {
            status: studentWorksheet.status,
            autoScored: worksheet.autoScorable,
            kind: 'WORKSHEET',
            type: worksheet.type,
            worksheetId: worksheet.id,
          },
          studentAppSetting: commonRepo.studentAppSetting,
        })
      }
    })
  }

  private _createScoring = ({
    worksheetProblemId,
    scoring,
    problem,
    index,
  }: Pick<
    Awaited<ReturnType<typeof studentWorksheetApi.getAssignedStudentWorksheetWithProblems>>[number],
    'worksheetProblemId' | 'scoring' | 'problem'
  > & { index: number }) => {
    return new StudentWorksheetScoringEntity.StudentWorksheetScoring({
      scoring: { ...scoring, worksheetProblemId },
      problem: {
        ...problem,
        answerUnits: problem.answerUnits,
        keypadTypes: problem.keypadTypes,
      },
      worksheet: {
        school: this.worksheet?.school,
        grade: this.worksheet?.grade,
        autoScorable: this.worksheet?.autoScorable ?? false,
        label: `${index + 1}번`, // label을 밖에서 주입해줘야 하는 경우에는 받을 수 있도록 (ex. 1번 유사문제)}
      },
    })
  }
  // 한문제씩 가채점 제출
  async onVirtualScoringSubmitById(
    studentWorksheetId: StudentWorksheetEntity.StudentWorksheet['id'],
    localUserInput: RequestScoring.자동채점<'WORKSHEET'>,
  ) {
    const params = RequestScoring.자동채점.toRequestParams<'WORKSHEET', '일반'>(localUserInput)
    await studentWorksheetApi.postVirtualAutoScoringProblem(studentWorksheetId, [
      {
        worksheetProblemId: params.worksheetProblemId,
        userAnswer: params.userAnswer,
        unknown: params.unknown,
      },
    ])
    // 하위호환성용 코드
    localStorageService.removeScoringData({ scoringId: params.worksheetProblemId })
  }
  // 초기의 로컬스토리지 데이터를 다 가채점 제출
  async onVirtualScoringInit(
    studentWorksheetId: StudentWorksheetEntity.StudentWorksheet['id'],
    localUserInputs: RequestScoring.자동채점<'WORKSHEET'>[],
  ) {
    const params = localUserInputs.map((localUserInput) => {
      const _data = RequestScoring.자동채점.toRequestParams<'WORKSHEET', '일반'>(localUserInput)
      localStorageService.removeScoringData({ scoringId: _data.worksheetProblemId })
      return {
        worksheetProblemId: _data.worksheetProblemId,
        userAnswer: _data.userAnswer,
        unknown: _data.unknown,
      }
    })
    await studentWorksheetApi.postVirtualAutoScoringProblem(studentWorksheetId, params)
  }

  async onDeleteVirtualScoring(studentWorksheetId: StudentWorksheetEntity.StudentWorksheet['id']) {
    await studentWorksheetApi.deleteVirtualAutoScoringProblem(studentWorksheetId)
  }

  async onSubmitWorksheetProblems(studentWorksheetId: string) {
    try {
      const payload = this.problemScoringColl?.toScoredArr

      if (payload && payload.length > 0) {
        if (payload[0].localUserInput instanceof RequestScoring.자동채점) {
          await studentWorksheetApi.patchAutoScoringAssignedStudentWorksheet(
            studentWorksheetId,
            payload.map(({ localUserInput }) =>
              RequestScoring.자동채점.toRequestParams<'WORKSHEET', '일반'>(
                localUserInput as RequestScoring.자동채점,
              ),
            ),
          )
        } else {
          // 일반채점이라면
          await studentWorksheetApi.patchScoringAssignedStudentWorksheet(
            studentWorksheetId,
            payload.map(({ localUserInput }) =>
              RequestScoring.일반채점.toRequestParams<'WORKSHEET', '일반'>(
                localUserInput as RequestScoring.일반채점,
              ),
            ),
          )
        }

        this.problemScoringColl?.toScoredArr?.forEach((item) => {
          localStorageService.removeScoringData({ scoringId: item.id })
        })

        await this.loadAndSetStudentWorksheetScorings(+studentWorksheetId)

        // patchAutoScoringAssignedStudentWorksheet 성공 여부 확인을 위해 return
        return payload?.map((item) => item.id)
      }
    } catch (err: any) {
      console.log(err)
      if (err?.response?.data?.code === 'STUDENT_WORKSHEET_PROBLEM_DUPLICATE_SUBMIT') {
        toastService.error('이미 제출한 답안은 수정할 수 없습니다.', { allowMultipleToast: true })
      }
    }
  }
}
