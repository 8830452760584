import styled from '@emotion/styled'
import { WorksheetDomain } from '@mathflat/domain/@entities'
import type { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'

import { colors, textEllipsis, typo } from '~/@common/styles'
import Katex from '~/@common/ui/Katex/Katex'
import type { ProblemScoring } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'
import ProblemScoringFooter from '~/@pages/@common/(ProblemScoring)/ProblemScoringCard/ProblemScoringFooter'
import 자동채점폼 from '~/@pages/@common/(Scoring)/(자동채점)/자동채점폼'

import type { WorksheetScoringByOneProps } from '../../WorksheetScoringByOne'
import { ScoringResultBubble } from './ScoringResultBubble'

type Props = {
  type: WorksheetEntity.Worksheet['type']
  isLastProblem: boolean
  isSubmittable: boolean
  problemScoring: ProblemScoring<'WORKSHEET'>
  onNextOrSubmit: () => void
} & Pick<
  WorksheetScoringByOneProps<'출제' | '미출제'>,
  'isLastScreen' | 'viewOption' | 'onLocalUserInputChange'
>

const WorksheetScoringByOneBodyFooter = (props: Props) => {
  if (!props.problemScoring.isAutoScoring) {
    return null
  }

  return (
    <S.WorksheetScoringByOneBodyFooter onTouchMove={(e) => e.stopPropagation()}>
      <_WorksheetScoringByOneBodyFooter {...props} />
    </S.WorksheetScoringByOneBodyFooter>
  )
}

const _WorksheetScoringByOneBodyFooter = ({
  type,
  isLastProblem,
  isSubmittable,
  problemScoring,
  isLastScreen = false,
  viewOption,
  onNextOrSubmit,
  onLocalUserInputChange,
}: Props) => {
  if (type === WorksheetDomain.TYPE.챌린지 || type === WorksheetDomain.TYPE.CONCEPTUAL) {
    return (
      <자동채점폼
        className={viewOption?.content.type === 'CONCEPTUAL' ? 'conceptual-form' : undefined}
        buttonText={isLastProblem ? (problemScoring.isSubmitted ? '다음' : '제출하기') : '다음'}
        onSubmitClick={onNextOrSubmit}
      >
        {problemScoring.isSubmitted ? (
          <UserSubmittedAnswer problemScoring={problemScoring} viewOption={viewOption} />
        ) : (
          <ProblemScoringFooter
            problemScoring={problemScoring}
            viewOption={viewOption}
            virtualKeybaordShowType="fixed"
          />
        )}
      </자동채점폼>
    )
  }

  if (type === WorksheetDomain.TYPE.자기주도학습) {
    return (
      <자동채점폼
        buttonText={
          !problemScoring.isSubmitted && isLastScreen && isLastProblem ? '제출하기' : '다음'
        }
        onSubmitClick={onNextOrSubmit}
      >
        {problemScoring.isSubmitted ? (
          <UserSubmittedAnswer problemScoring={problemScoring} viewOption={viewOption} />
        ) : (
          <ProblemScoringFooter
            problemScoring={problemScoring}
            viewOption={viewOption}
            virtualKeybaordShowType="fixed"
          />
        )}
      </자동채점폼>
    )
  }

  return (
    <자동채점폼
      buttonText={isLastProblem ? '제출하기' : '다음'}
      opt={{
        buttonProps: {
          style: {
            backgroundColor: isLastProblem && !isSubmittable ? '#395FE24D' : '',
          },
        },
      }}
      onSubmitClick={onNextOrSubmit}
    >
      {problemScoring.isSubmitted ? (
        <UserSubmittedAnswer problemScoring={problemScoring} viewOption={viewOption} />
      ) : (
        <ProblemScoringFooter
          problemScoring={problemScoring}
          viewOption={viewOption}
          virtualKeybaordShowType="fixed"
          onLocalUserInputChange={onLocalUserInputChange}
        />
      )}
    </자동채점폼>
  )
}

export default WorksheetScoringByOneBodyFooter

interface UserSubmittedAnswerProps {
  problemScoring: ProblemScoring<'WORKSHEET'>
  viewOption: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'> | undefined
}

export const UserSubmittedAnswer = ({ problemScoring, viewOption }: UserSubmittedAnswerProps) => {
  const isConceptualLearning = viewOption?.content.type === 'CONCEPTUAL'
  const bubbleLeftOffset = isConceptualLearning ? 113 : 0
  const bubbleBottomOffset = isConceptualLearning ? 18 : 0

  return (
    <S.SubmittedAnswer>
      <ScoringResultBubble
        scoringResult={problemScoring.채점결과}
        leftOffset={bubbleLeftOffset}
        bottomOffset={bubbleBottomOffset}
      />
      {problemScoring.isAutoScoring && problemScoring.제출한답 === null ? (
        <div className="answer__wrapper">
          <span className="answer-guide">선생님이 채점한 문제예요</span>
        </div>
      ) : (
        <div className="answer__wrapper">
          <span>제출한 답:</span>
          <span className="answer__elipsis">
            {problemScoring.채점결과 === 'UNKNOWN' ? (
              '모름'
            ) : problemScoring.문제정답타입 === 'SHORT_ANSWER' ? (
              <Katex>{problemScoring.제출한답}</Katex>
            ) : (
              problemScoring.제출한답
            )}
          </span>
        </div>
      )}
    </S.SubmittedAnswer>
  )
}

const S = {
  WorksheetScoringByOneBodyFooter: styled.div`
    display: flex;
    flex: 0 0 36px;
  `,
  SubmittedAnswer: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 36px;
    padding: 0 10px;
    border-radius: var(--Radius-200);
    background-color: ${colors.gray.$100};
    color: ${colors.gray.$900};
    ${typo.caption01};
    .answer__wrapper {
      display: flex;
      gap: 2px;
    }
    .answer__elipsis {
      ${textEllipsis(1)}
      max-width: 103px;
    }
    .answer-guide {
      color: ${colors.gray.$600};
    }
  `,
}
