import * as S from '@effect/schema/Schema'
import { ISODateString } from '@mathflat/shared/@common/schema/schema'
import { apiSpec } from '@mathflat/shared/@modules/ApiSpec/apiSpec.ts'
import type {
  ApiSpecInputParams,
  ApiSpecOutputData,
  ApiSpecRouteParams,
} from '@mathflat/shared/@modules/ApiSpec/types'
import { pipe } from 'effect'

import { ConceptDomain } from '~/@entities/Concept/domain.ts'

import { CurriculumDomain, LearningProcessDomain, ProblemDomain } from '..'
import {
  type GradeElementaryMiddle,
  GradeElemMiddle,
  SchoolType,
  Semester,
} from '../SchoolSystem/schema'

const gradeTransform = pipe(
  S.number,
  S.transform(
    GradeElemMiddle,
    (n) => String(n) as GradeElementaryMiddle,
    (s) => parseInt(s),
  ),
)

export module LearningProcessApi {
  /**
   * 학습시간저장 <br/>
   * link: https://freewheelin.postman.co/workspace/ef6c4fe1-201a-4de6-8b0c-2a7de439d3ff/request/12065882-8b6da65c-fa2f-48ed-8fdf-ee5599cd212b?active-environment=4e1b2553-17d6-4236-b386-49d9284e148c
   */
  export module 학습시간저장 {
    export const spec = apiSpec({
      method: 'POST',
      path: '/student-learning/time/:minute',
      responseData: S.void,
    })

    export type PathParams = ApiSpecRouteParams<typeof spec>
  }

  /**
   * 개념학습하기 <br/>
   * link: https://freewheelin.postman.co/workspace/ef6c4fe1-201a-4de6-8b0c-2a7de439d3ff/request/12065882-2a7ad0db-1e0f-4fd2-9922-813f00993d5f?ctx=documentation
   */
  export module 개념학습하기 {
    // 학습 강의 스키마
    export const LearnedLectures = S.struct({
      studentLecture: S.nullable(
        S.struct({
          studentId: S.string,
          lectureId: S.number,
          createDatetime: ISODateString.pipe(S.nullable),
        }),
      ),
      lecture: S.struct({
        id: S.number,
        title: S.string,
        curriculumId: S.number,
        thumbnailUrl: S.string,
        videoUrl: S.string,
        orderingNumber: S.number,
        subtitleUrl: S.string.pipe(S.nullable),
      }),
    })
    export const LearnedConcepts = S.struct({
      id: S.number,
      curriculumId: S.number,
      school: SchoolType,
      grade: GradeElemMiddle,
      semester: Semester,
      name: S.string,
      url: S.string,
      // TODO: 백엔드한테 이게 뭔지 물어봐야 함
      outOfCurriculumType: CurriculumDomain.OutOfCurriculumType,
      hidden: S.boolean,
      conceptSummary: ConceptDomain.Summary,
    })

    export const spec = apiSpec({
      method: 'GET',
      path: '/student-learning/self-learning/steps/concept',
      params: S.struct({
        studentWorksheetId: S.string,
      }),
      responseData: S.struct({
        lectures: S.array(LearnedLectures),
        prevLectures: S.array(LearnedLectures),
        concepts: S.array(LearnedConcepts),
        prevConcepts: S.array(LearnedConcepts),
      }),
    })

    export type Params = ApiSpecInputParams<typeof spec>
    export type Output = ApiSpecOutputData<typeof spec>

    // grade Number로 내려오는 것 대응
    // spec 사용단에서는 string으로 변환
    // 실제 타입은 _안붙은 타입으로 관리
    export type _Output = ApiSpecOutputData<typeof _spec>
    const _LearnedConcepts = S.struct({
      id: S.number,
      curriculumId: S.number,
      school: SchoolType,
      grade: gradeTransform,
      semester: Semester,
      name: S.string,
      url: S.string,
      // TODO: 백엔드한테 이게 뭔지 물어봐야 함
      outOfCurriculumType: CurriculumDomain.OutOfCurriculumType,
      hidden: S.boolean,
      conceptSummary: ConceptDomain.Summary,
    })
    export const _spec = apiSpec({
      method: 'GET',
      path: '/student-learning/self-learning/steps/concept',
      params: S.struct({
        studentWorksheetId: S.string,
      }),
      responseData: S.struct({
        lectures: S.array(LearnedLectures),
        prevLectures: S.array(LearnedLectures),
        concepts: S.array(_LearnedConcepts),
        prevConcepts: S.array(_LearnedConcepts),
      }),
    })
  }

  /**
   * 오답학습하기 <br/>
   * link: https://freewheelin.postman.co/workspace/ef6c4fe1-201a-4de6-8b0c-2a7de439d3ff/request/12065882-0f33967c-44b3-4765-9ddb-f96c566400a6?ctx=documentation
   */
  export module 오답학습하기 {
    export const Scoring = S.struct({
      studentWorksheetScoring: S.struct({
        worksheetProblemId: S.number,
        result: ProblemDomain.ScoringResult,
        userAnswer: S.string.pipe(S.nullable),
        handwrittenNoteUrl: S.string.pipe(S.nullable),
      }),
      worksheetProblem: S.struct({
        orderingNumber: S.number,
        tagTop: S.string,
      }),
      problem: LearningProcessDomain.Problem,
    })

    export const spec = apiSpec({
      method: 'GET',
      path: '/student-learning/self-learning/steps/wrong',
      params: S.struct({
        studentWorksheetId: S.string,
      }),
      responseData: S.array(
        S.struct({
          wrongLearningKey: S.struct({
            problem: LearningProcessDomain.Problem.pipe(S.nullable),
            concept: ConceptDomain.Concept,
          }),
          prevScoringToPairOrSimilarScorings: S.array(
            S.struct({
              prevScoring: Scoring,
              pairScorings: S.array(Scoring),
              similarScorings: S.array(Scoring),
            }),
          ),
        }),
      ),
    })

    export type Params = ApiSpecInputParams<typeof spec>
    export type Output = ApiSpecOutputData<typeof spec>
  }
}
