import type { AxiosInstance } from 'axios'

export type PollingOptions = {
  intervalTime?: number
  executeImmediately?: boolean
}

export type PollingCallback<T> = {
  callback: () => Promise<T>
  onSuccess?: (data: T) => void
  onError?: (error: Error) => void
}

export class AxiosPollingClient {
  axiosInstance: AxiosInstance
  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance
  }
  listenMessagePolling<T>(fn: PollingCallback<T>, options?: PollingOptions) {
    const { callback, onSuccess, onError } = fn
    const { intervalTime = 5000, executeImmediately = false } = options ?? {}

    let interval: number = 0
    let isOffline = false

    if (executeImmediately) {
      // 즉시 실행 옵션을 주면 최초 바로 실행
      callback()
        .then((data) => onSuccess?.(data))
        .catch((error) => onError?.(error as Error))
    }

    interval = window.setInterval(async () => {
      try {
        if (navigator.onLine) {
          if (isOffline) {
            isOffline = false
          }
          const data = await callback()
          onSuccess?.(data)

          return
        } else {
          isOffline = true
        }
      } catch (error) {
        onError?.(error as Error)
      }
    }, intervalTime)

    return {
      interval,
      stop: () => {
        clearInterval(interval)
      },
    }
  }
}
