import styled from '@emotion/styled'
import { WorksheetDomain } from '@mathflat/domain/@entities'
import { observer } from 'mobx-react'
import type SwiperCore from 'swiper'
import { Navigation } from 'swiper/modules'
import { Controller } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useRepository } from '~/@common/hooks/useRepository.ts'
import { AiTutorService } from '~/@common/services/AiTutor.service.ts'
import { CustomEventService } from '~/@common/services/event.service'
import {
  SwiperControllerConsumerName,
  useSwiperControllerContext,
} from '~/@common/ui/SwiperController/SwiperController.context'
import { ProblemSolvingStatus } from '~/@pages/@common/ProblemSolvingStatusBoard/ProblemSolvingStatusBoard'
import ConceptualLearningScoringFooter from '~/@pages/student/conceptual-learning/@widgets/ui/ConceptualLearningScoringFooter'

import type { WorksheetScoringByOneProps } from '../WorksheetScoringByOne'
import { WorksheetScoringByOneSlide } from './WorksheetScoringByOneSlide'

type Props<T extends '출제' | '미출제'> = {
  isProblemSolvingStatusShown: boolean
  problemScoringColl: NonNullable<WorksheetScoringByOneProps<T>['problemScoringColl']>
  worksheet: NonNullable<WorksheetScoringByOneProps<T>['worksheet']>
  openExitConfirmationAiTutor?: ({ onConfirm }: { onConfirm: () => void }) => void
} & WorksheetScoringByOneProps<T>

const WorksheetScoringByOneBody = <T extends '출제' | '미출제'>({
  isProblemSolvingStatusShown,
  isLastScreen,
  problemScoringColl,
  worksheet,
  viewOption,
  studentWorksheet,
  problemIndex,
  onProblemIndexChange,
  onSubmit,
  isAiTutorOpened,
  openExitConfirmationAiTutor,
  containerTopNode,
  onLocalUserInputChange,
}: Props<T>) => {
  const swiperController = useSwiperControllerContext(SwiperControllerConsumerName)
  const contentId = studentWorksheet?.id ?? worksheet.id

  const initSwiperInstance = (swiper: SwiperCore) => {
    swiperController.setSwiper(swiper)
  }

  const aiTutorService = useRepository(AiTutorService)

  return (
    <S.WorksheetScoringByOneBody>
      {isProblemSolvingStatusShown && worksheet.type !== WorksheetDomain.TYPE.자기주도학습 && (
        <ProblemSolvingStatus problemScorings={problemScoringColl.toArr} />
      )}
      <Swiper
        modules={[Navigation, Controller]}
        slidesPerView={problemScoringColl.toArr.length <= 2 ? 1 : 'auto'}
        spaceBetween={100}
        touchAngle={30}
        threshold={6}
        grabCursor
        controller={{ control: swiperController.control }}
        navigation={{
          prevEl: '.ui__SwiperController_prevButton',
          nextEl: '.ui__SwiperController_nextButton',
        }}
        onInit={initSwiperInstance}
        initialSlide={problemIndex ?? 0}
        onSlideChange={(swiper) => {
          onProblemIndexChange?.(swiper.activeIndex)
          CustomEventService.tooltipOff.dispatch() // 슬라이드 변경시 키패드 툴팁을 닫기 위한 로직
        }}
        allowTouchMove={false}
      >
        {problemScoringColl.toArr.map((problemScoring, index) => {
          const isLastProblem = index + 1 === problemScoringColl?.toArr.length

          return (
            <SwiperSlide key={problemScoring.id}>
              <WorksheetScoringByOneSlide
                titleIngredients={[
                  worksheet.type === WorksheetDomain.TYPE.CUSTOM
                    ? problemScoring.problem.conceptName || ''
                    : '',
                  problemScoring.problem.problemSummary?.answerRate
                    ? '정답률 ' + problemScoring.problem.problemSummary?.answerRate + '%'
                    : '',

                  '난이도 ' + problemScoring.난이도,
                  problemScoring.problem.tagTop ? problemScoring.problem.tagTop : '',
                ]}
                isLastProblem={isLastProblem}
                isSubmittable={!!problemScoringColl?.toScoredArr.length}
                problemsCount={problemScoringColl?.toArr.length}
                problemScoring={problemScoring}
                onNextOrSubmit={() => {
                  if (isLastProblem) {
                    onSubmit()
                    return
                  }

                  if (isAiTutorOpened) {
                    openExitConfirmationAiTutor?.({
                      onConfirm: () => {
                        aiTutorService.close()
                        swiperController.control?.slideNext()
                      },
                    })
                    return
                  }
                  aiTutorService.close()
                  swiperController.control?.slideNext()
                }}
                viewOption={viewOption}
                isLastScreen={isLastScreen}
                type={worksheet.type}
                contentId={`${contentId}`}
                disableWritting={worksheet.type === 'CONCEPTUAL'}
                containerTopNode={containerTopNode}
                footerNode={
                  worksheet.type === 'CONCEPTUAL' && problemScoring.isSubmitted ? (
                    <ConceptualLearningScoringFooter
                      problemScoring={problemScoring}
                      viewOption={viewOption}
                    />
                  ) : undefined
                }
                onLocalUserInputChange={onLocalUserInputChange}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </S.WorksheetScoringByOneBody>
  )
}

export default observer(WorksheetScoringByOneBody)

const S = {
  WorksheetScoringByOneBody: styled.div`
    display: flex;
    gap: 25px;
    overflow: hidden;
    flex: 1 1 100%;
  `,
}
